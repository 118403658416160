

import React, { useContext, useState } from 'react';
import PlacesAutocomplete, { geocodeByAddress, getLatLng, } from 'react-places-autocomplete';
import { GoogleMap, LoadScript, MarkerF } from "@react-google-maps/api";
import { IoLocation, IoCloseCircle } from "react-icons/io5";
import { useSelector, useDispatch } from 'react-redux';
const libraries = ["drawing", "places"];
const AddressAutoComplete = ({ errors }) => {
    const dispatch = useDispatch()
    const address = useSelector(state => state.addressdata.address);
    const handleSelect = async (selectedAddress) => {
        const results = await geocodeByAddress(selectedAddress);
        const latLng = await getLatLng(results[0]);


        // Extracting formatted address components
        const formatted = results[0].formatted_address;
        dispatch({
            type: 'setcustomegeoaddress', payload: results
        })
        // dispatch({
        //     type: 'setcustomegeoaddress', payload: results
        // })
        var pincode = results[0].address_components.filter(ele => ele.types.includes("postal_code"));
        dispatch({
            type: 'setonchangeadress', payload: selectedAddress
        })
        dispatch({
            type: 'setAddres', payload: selectedAddress
        })
    };
    const handleAddressChange = (newAddress) => {
        // Dispatch the action to set the address
        dispatch({ type: 'setAddres', payload: newAddress });
    };
    // const searchOptions = {
    //     componentRestrictions: { country: 'in' } // Restrict results to India
    // };
    const searchOptions = {};
    return (
        <div className='pb-3 mb-3'>
            <LoadScript
                googleMapsApiKey={"AIzaSyBwyUWzedpphw9uXUDp9rHGuJD35ZVMYws"}
                libraries={libraries}
            >
                {/* <script  
    src="https://maps.googleapis.com/maps/api/js?key=YOUR_API_KEY&loading=async&callback=initMap">
    </script> */}
                {/* <script async src={`https://maps.googleapis.com/maps/api/js?key=AIzaSyBwyUWzedpphw9uXUDp9rHGuJD35ZVMYws&libraries=${libraries.join(",")}&language=en`} defer></script> */}

                {/* <script async src={`https://maps.googleapis.com/maps/api/js?key=AIzaSyBwyUWzedpphw9uXUDp9rHGuJD35ZVMYws&loading=async&libraries=${libraries.join(",")}&language=en`} defer></script> */}
                <PlacesAutocomplete
                    value={address} AIzaSyBwyUWzedpphw9uXUDp9rHGuJD35ZVMYws
                    onChange={handleAddressChange}
                    onSelect={handleSelect}
                    searchOptions={searchOptions}
                >
                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (

                        < div>

                            <div style={{ position: "relative", width: "100%" }}>

                                <div className='autocomplete-inputbox-main'>
                                    <input
                                        {...getInputProps({
                                            placeholder: 'Search Places...',
                                            className: 'location-search-input autocomplete-inputbox',

                                        })}
                                        style={{
                                            boxSizing: "border-box",
                                            border: "1px solid #000",
                                            width: "100%",
                                            height: "32px",
                                            //   padding: 0 12px,
                                            //   borderRadius: 3px,
                                            //   boxShadow: 0 2px 6px rgba(0, 0, 0, 0.3),
                                            //   fontSize: 14px,
                                            //   outline: none,
                                            //   textOverflow: ellipses,
                                            //   margin: 10px 0px,
                                            position: 'relative',
                                        }}
                                    />
                                    <div><IoLocation className='autocomplete-inputbox-searchbox' /></div>
                                    {/* <div onClick={() => setAddres("")}><IoCloseCircle className='autocomplete-inputbox-wrong' /></div> */}
                                </div>

                                {suggestions.length > 0 &&
                                    <div className="autocomplete-dropdown-container">
                                        {
                                            suggestions.map((suggestion) => {
                                                const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
                                                // Modify the styling as per your requirement

                                                const [first, ...remain] = suggestion.terms;
                                                return (
                                                    <>
                                                        <div
                                                            {...getSuggestionItemProps(suggestion, {
                                                                className,
                                                            })}
                                                        >
                                                            <div className='fjdfdjh'>
                                                                {first.value}
                                                            </div>
                                                        </div>
                                                        <div
                                                            {...getSuggestionItemProps(suggestion, {
                                                                className,
                                                            })}
                                                        >
                                                            <div className='fjdfdjhgfh'>
                                                                {
                                                                    remain.length > 0 &&
                                                                    remain.map((ert, index) => {
                                                                        return (
                                                                            <>
                                                                                {ert.value}
                                                                                {index !== remain.length - 1 && ', '}
                                                                            </>
                                                                        )
                                                                    })}
                                                            </div>
                                                        </div>
                                                    </>
                                                );
                                            })}

                                    </div>
                                }

                            </div>
                        </div>

                    )}
                </PlacesAutocomplete>

            </LoadScript>
            <div className='address-error'>{errors.address}</div>
        </div >
    );
};

export default AddressAutoComplete;






import React, { useContext, useEffect, useState } from 'react';
import { BsXLg } from 'react-icons/bs';
import { useMediaQuery } from "react-responsive";
import { useSelector, useDispatch } from 'react-redux';

const PaymentMethodComp = ({ screentype, paymentData, type, deliverycharge }) => {
    const dispatch = useDispatch()
    const cartDetail = useSelector(state => state.cartdetail.cartDetail);
    const bottomsheet = useSelector(state => state.cartdetail.bottomsheet);
    const discount = useSelector(state => state.cartdetail.discount);
    const isDesktoporMobile = useMediaQuery({ query: `(max-width: 991px)` });
    const [updateddiscount, setupdateddiscount] = useState(0);
    useEffect(() => {
        setupdateddiscount(
            parseFloat(cartDetail.total_mrp.replace(/,/g, '')) - 
            parseFloat(cartDetail.total.replace(/,/g, ''))
          );
          
    }, [cartDetail])
    return (
        <>
            {
                type == 'dropdown' ?
                    <div className='justify-space-between d-flex'>
                        <div className='payment_header'>Payment Details</div>
                        <BsXLg onClick={() => dispatch({ type: 'setbottomsheet', payload: !bottomsheet })} />
                    </div> :
                    <div className='payment_header'>Payment Details</div>
            }
            <div className='detail_row'>
                <div className='detail_row_left'>MRP Total</div>
                <div className='detail_row_right'>< span className="currencysymbol">{localStorage.getItem("currency")} </span>{screentype == 'order-detail' ? paymentData.selling_price : cartDetail.total_mrp}</div>
            </div>
            {
                updateddiscount != 0 &&
                <div className='detail_row'>
                    <div className='detail_row_left'>Discount</div>
                    <div className='detail_row_right active-font-size-green'>- < span className="currencysymbol">{localStorage.getItem("currency")} </span> {screentype == 'order-detail' ? 0 : (updateddiscount.toFixed(2))}</div>
                </div>
            }
            {
                discount > 0 &&
                <div className='detail_row'>
                    <div className='detail_row_left'>Voucher Discount</div>
                    <div className='detail_row_right active-font-size-green'>- < span className="currencysymbol">{localStorage.getItem("currency")} </span>{(discount)}</div>
                </div>
            }
            <div className='detail_row'>
                <div className='detail_row_left'>Delivery Charges</div>
                <div className='detail_row_right active-font-size-green'>
                    {/* Exact: {cartDetail.total_shipping_cost + deliverycharge},  */}
                    < span className="currencysymbol">{localStorage.getItem("currency")} </span> {(cartDetail.total_shipping_cost + deliverycharge)}
                </div>

            </div>
            <div className='detail_row'>
                <div className='detail_row_left'>Total</div>
                <div className='detail_row_right d-flex active-font-size-black'>
                    < span className="currencysymbol paymenttotalcurrency_symbol">{localStorage.getItem("currency")} </span>
                    {screentype === 'order-detail'
                        ? ""
                        : (
                            ((Number(cartDetail.total.replace(/,/g, '')) + cartDetail.total_shipping_cost + (deliverycharge)) - discount).toFixed(2)
                        )
                    }
                </div>

            </div>

        </>
    );
};

export default PaymentMethodComp;
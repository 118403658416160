import React, { useState,useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { BsChevronLeft, BsSearch } from 'react-icons/bs';
import { NavLink } from 'react-router-dom';
import SearchAction from '../redux/actions/SearchAction';
import { useSelector, useDispatch } from 'react-redux';
import { MdDeleteForever } from "react-icons/md";
import logo from '../images/bg_image.webp';

const Search = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const goBack = () => navigate(-1);
    const [searchValue, setValue] = useState('');
    const [clearHistory, setClearHistory] = useState(false);
    const searchDetail = useSelector(state => state.catlogdetail.searchDetail);
    const showsearchdata = useSelector(state => state.catlogdetail.showsearchdata);
    const { getSearchProduct } = SearchAction();
    useEffect(() => {
        if (clearHistory) {
            localStorage.removeItem('searchHistory');
            setClearHistory(false); // Reset state to avoid repeated clearing
        }
    }, [clearHistory]);
    const handleChange = (e) => {
        setValue(e.target.value);
        const input_data = { search: e.target.value };
        dispatch(getSearchProduct(input_data));
    };
    
    const clearAllHistory = () => {
        setClearHistory(true);
    };

    const searchButton = () => {
        if (searchValue.trim() !== '') {
            navigate(`/search-result/${searchValue}`);
            let searchHistory = JSON.parse(localStorage.getItem('searchHistory')) || [];
            searchHistory.unshift(searchValue);
            if (searchHistory.length > 10) {
                searchHistory.pop();
            }
            localStorage.setItem('searchHistory', JSON.stringify(searchHistory));
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            searchButton();
        }
    };

    const searchHistoryResults = (item) => {
        navigate(`/search-result/${item}`);
    };

    // const clearAllHistory = () => {
    //     localStorage.removeItem('searchHistory');
    // };

    return (
        <div>
            <div className="search">
                <BsChevronLeft onClick={goBack} size="24" className="search-left-icon" />
                <div className="searchinput">
                    <input
                        type="text"
                        className="custom-search-input"
                        placeholder="Search here ..."
                        onChange={handleChange}
                        onKeyDown={handleKeyDown}
                        value={searchValue}
                    />
                    <BsSearch size="14" onClick={searchButton} className="custom-search-inputicon" />
                </div>
            </div>
            <div className="search-products">
                {JSON.parse(localStorage.getItem('searchHistory'))?.length > 0 && (
                    <div className="search-heading">
                        <h6 className="search-history-title">Search History</h6>
                        <div className="searchhistory-delete-button" onClick={clearAllHistory} style={{ cursor: 'pointer' }}>
                            <MdDeleteForever size={14} />
                        </div>
                    </div>
                )}
                <div className="searchHistory-main-elements">
                    {JSON.parse(localStorage.getItem('searchHistory'))?.map((ele, index) => (
                        <h6
                            className="searchHistory-elements"
                            key={index}
                            onClick={() => searchHistoryResults(ele)}
                        >
                            {ele}
                        </h6>
                    ))}
                </div>
            </div>
            {showsearchdata && searchDetail?.length > 0 ? (
                <div className="searchDetail">
                    {searchDetail.map((ele) => (
                        <NavLink
                            key={ele.slug}
                            onClick={() => dispatch({ type: 'setshowdata', payload: false })}
                            to={`/product/${ele.slug}`}
                            className="text-decoration-none"
                        >
                            <div className="searchbar-product-list">
                                {ele.product_images?.length > 0 && (
                                    <img
                                        referrerPolicy="no-referrer"
                                        src={ele.product_images[0]}
                                        onError={(e) => {
                                            e.currentTarget.src = logo;
                                        }}
                                        alt={ele.product_title}
                                    />
                                )}
                                <div>{ele.product_title}</div>
                            </div>
                        </NavLink>
                    ))}
                </div>
            ) : (
                showsearchdata && <div>No record found</div>
            )}
        </div>
    );
};

export default Search;

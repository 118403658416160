import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAC_HGc8_w69gXjnPVLWABfHllQy1vWtlM",
  authDomain: "apnashope-6fe99.firebaseapp.com",
  projectId: "apnashope-6fe99",
  storageBucket: "apnashope-6fe99.appspot.com",
  messagingSenderId: "799589421316",
  appId: "1:799589421316:web:13993114620710137f8051",
  measurementId: "G-66J7RXY6QR"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);


// Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getAuth } from "firebase/auth";
// const firebaseConfig = {
//   apiKey: "AIzaSyCwne8aIc4krxKC85f-uCtm09S9n4p7eBo",
//   authDomain: "kkcart-573fc.firebaseapp.com",
//   projectId: "kkcart-573fc",
//   storageBucket: "kkcart-573fc.firebasestorage.app",
//   messagingSenderId: "124390447056",
//   appId: "1:124390447056:web:c70bae2461fb25f1210bd5"
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);
// export const auth = getAuth(app);
import React, { useEffect, useState } from "react";
import { FaStar, FaRegStar, FaPlus } from "react-icons/fa6";
import logo from '../../images/bg_image.webp';
import { NavLink, useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import Varients from "./Varients";
import { IoCloseCircleSharp } from "react-icons/io5";
import { FaRegHeart, FaHeart } from "react-icons/fa";
import { BiSolidCart } from "react-icons/bi";
import { IoStar } from "react-icons/io5";
import CartAction from '../../redux/actions/CartAction';
import ProductAction from "../../redux/actions/ProductAction";
import Varientdropdown from "./Varientdropdown";
import { useMediaQuery } from "react-responsive";
import CartSidebar from "../common/CartSidebar"
import MobilVarient from "./MobileVarient";
const CardProduct = ({ discount, image, wishlist, className1, title, slug, price, status, rating, productid, mrp, variantList, stock_status, product_type }) => {
    const isDesktoporMobile = useMediaQuery({ query: `(max-width: 991px)` });
    const [searchParams, setSearchParams] = useSearchParams();
    const cartDetail = useSelector(state => state.cartdetail.cartDetail);
    const showSidebar = useSelector(state => state.masterdata.showSidebar)
    const [wishliststatus, setWishliststatus] = useState(false)
    const [addtocartstatus, setaddtocartstatus] = useState(false)
    const { addToCartProduct, deleteCart } = CartAction();
    const [changeproductid, setchangeProductid] = useState("000")
    const { getbundlelist } = ProductAction();
    const { addToWishList } = CartAction(searchParams);
    const dispatch = useDispatch();
    // useEffect(() => {
    //     if (variantList && variantList.length > 0) {
    //         // alert()
    //         dispatch({
    //             type: 'setproductvarientdata',
    //             payload: variantList[0]
    //         });
    //     }
    // }, []);

    const navigate = useNavigate();
    const addtocartpage = useSelector(state => state.cartdetail.addtocartpage);
    const productvarientdata = useSelector(state => state.masterdata.productvarientdata);

    const authStatus = useSelector(state => state.masterdata.authStatus);
    const renderStars = () => {
        let stars = [];
        for (let i = 0; i < 5; i++) {
            if (i < rating) {
                stars.push(<FaStar key={i} />);
            } else {
                stars.push(<FaRegStar key={i} />);
            }
        }
        return stars;
    };
    useEffect(() => {
        if (cartDetail.result.length > 0) {
            var status = (cartDetail.result.filter(ele => (ele.cart_status == 2 && ele.product_id == productid)))

            if (status.length > 0) {
                setWishliststatus(true)
            } else {
                setWishliststatus(false)
            }
        }
    }, [cartDetail, productid])

    const handleAddToCart = (id, image, price) => {

        if (!authStatus) {
            const randomId = Math.floor(Math.random() * 100000000);
            if (localStorage.getItem("sessionId") === null) {
                localStorage.setItem('sessionId', randomId);
            }
        }
        const inputData = {
            ...(variantList && variantList.length > 0 ? { "variantid": productvarientdata.variantid } : {}),
            "product_id": id,
            "quantity": 1,
            "type": 'product',
            ...(authStatus === false && { "session_id": localStorage.getItem("sessionId") })
        };
        dispatch(addToCartProduct(inputData));
        dispatch({
            type: 'setShowSidebar',
            payload: true
        });
        dispatch({
            type: 'setcartsidebardata',
            payload: {
                quantity: "1",
                image: image,
                price: price,
                slug: slug,
            }
        });
        document.body.style.overflow = "hidden";
        // {
        //     showSidebar && slug && (
        //         <CartSidebar quantity={1} image={image} price={price} slug={slug} />
        //     )
        // }

    };
    const clicked = (type, id) => {
        const input_data = {
            id: id,
            type: type,
        }
        dispatch(deleteCart(input_data, 'wishlist'))
    }
    useEffect(() => {

    }, [changeproductid, cartDetail])

    const removeWishlist = (type, id) => {
        setchangeProductid(id)
        if (cartDetail.result.length > 0) {
            var status = (cartDetail.result.filter(ele => (ele.product_id == id)))
            if (status.length > 0) {
                const input_data = {
                    id: status[0].id,
                    type: type,
                }
                dispatch(deleteCart(input_data, 'wishlist'))
            }

        }

    }
    const clickwishlist = (id, type) => {

        dispatch(addToWishList(id, type))
    }
    // useEffect(() => {
    if (addtocartpage === "Added to cart") {
        // alert()
        navigate("/cart");
        dispatch({
            type: 'setAddtocartpage',
            payload: ""
        });
        dispatch({
            type: "setcartpagerediredct",
            payload: true
        })
    }
    //  else if (addtocartpage) {
    //     // navigate(1);


    // }
    // }, [addtocartpage]);
    const bundleclick = () => {
        dispatch({
            type: "setbundleapicall",
            payload: product_type
        })
        dispatch({
            type: "setbundle_apislug",
            payload: slug
        })

    }

    return (
        <div className=" cardproduct-main" >

            <div className="wishlist-card-wrong">
                {
                    <div className="pro-img image_aspect_ratio">
                        <NavLink
                            to={`/product/${slug}${product_type === "bundle" ? `?type=${product_type}` : ''}`}
                            className="text-decoration-none"
                        >
                            <img src={image ? image :logo} onError={e => e.currentTarget.src = logo}  />
                        </NavLink>

                        <div className="productcard-status-discount">
                            {stock_status == 0 && <div className="sticker-new ">{"Out Of Stock"}</div>}
                            {/* {
                                stock_status == 1 && discount > 0 &&
                                (
                                    variantList && variantList.length > 0 ?
                                        (productvarientdata.rstatus == 1 &&
                                            <div className="sticker-sale primaryColor">{Math.round(100 - (productvarientdata.selling_price / productvarientdata.mrp) * 100)}% OFF</div>
                                        )
                                        :
                                        <div className="sticker-sale primaryColor">{discount}% OFF</div>
                                )
                            } */}
                        </div>
                        {!wishlist &&
                            (authStatus ?
                                (wishliststatus && cartDetail.result.length > 0 ?

                                    <div className={`add-to-cart  ${className1}`} data-toggle="tooltip" data-original-title="Move to Wishlist" onClick={() => removeWishlist('cart', productid)} >
                                        <div className="productcard-heart productcars-wishlisted" >
                                            <FaHeart className="wishlisticon-heart  productcars-wishlistedicon" />
                                        </div>
                                    </div> :
                                    <div className={`add-to-cart  ${className1}`} data-toggle="tooltip" data-original-title="Move to Wishlist" onClick={() => clickwishlist(productid, 'product')}>
                                        <div className="productcard-heart productcars-wishlisted" >
                                            <FaRegHeart className="wishlisticon-heart  productcars-wishlistedicon" />
                                        </div>
                                    </div>
                                ) :
                                <NavLink to={"/login"} className="text-decoration-none">
                                    <div className={`add-to-cart  ${className1}`} data-toggle="tooltip" data-original-title="Move to Wishlist">
                                        <div className="productcard-heart productcars-wishlisted" >
                                            <FaRegHeart className="wishlisticon-heart productcars-wishlistedicon" />
                                        </div>
                                    </div>
                                </NavLink>)
                        }

                        {
                            // rating && rating.length > 0 && 
                            <div className="productcard-rating">
                                {
                                    discount > 0 &&
                                    (
                                        variantList && variantList.length > 0 ?
                                            (productvarientdata.rstatus == 1 &&
                                                <div className=" ">  {Math.round(100 - (productvarientdata.selling_price / productvarientdata.mrp) * 100)}% </div>
                                            )
                                            :
                                            <div className="productdiscount  ">{discount}% Off </div>
                                    )
                                }
                            </div>
                        }
                    </div>
                }

                {
                    wishlist &&
                    <IoCloseCircleSharp className="whishlist-wrong-button" onClick={() => clicked('cart', productid)} />
                }
            </div>
            <div className="d-flex">
                <div className="producttitle-height">
                    <h6 className={`pro-title`}>
                        <NavLink to={`/product/${slug}`} className="text-decoration-none">
                            {title}
                        </NavLink>
                    </h6>
                </div>
                {
                    rating > 0 &&
                    <div className="product-rating">

                        <IoStar className="product-rating-icons" />
                        <span className="productrate">({rating})</span>
                    </div>
                }
            </div>

            <div className={`pro-content`}>

                <div className="product-card-title-price">

                    {/* <span className="product-brand">
                        Adidas
                    </span> */}

                    {
                        variantList && variantList.length > 0 ?
                            <div>
                                <p className="price-category-list">
                                    <span className={`price ${className1}`}><span className="productcard-price-symbol d-flex">{localStorage.getItem("currency")}</span> {productvarientdata.selling_price}</span>
                                    {
                                        stock_status == 0 ? "" :
                                            <>
                                                {mrp !== price && <span className="price cross-price d-flex"> < span className="currencysymbol">{localStorage.getItem("currency")} </span>{productvarientdata.mrp}</span>}
                                            </>
                                    }
                                    {/* {
                                        discount > 0 &&
                                        (
                                            variantList && variantList.length > 0 ?
                                                (productvarientdata.rstatus == 1 &&
                                                    <div className="sticker-sale ">  {Math.round(100 - (productvarientdata.selling_price / productvarientdata.mrp) * 100)}% OFF</div>
                                                )
                                                :
                                                <div className="sticker-sale ">{discount}% OFF</div>
                                        )
                                    } */}
                                </p>
                            </div> :
                            <div>
                                <div className="price-category-list">
                                    <div className="productcard-prices">
                                        <span className={`pricecost d-flex ${className1}`}> < span className="currencysymbol pricecardproduct">{localStorage.getItem("currency")} </span>{Number(price).toFixed(2)}</span>
                                        {
                                            stock_status == 0 ? "" :
                                                <>
                                                    {mrp !== price && <span className="pricecostcross cross-price d-flex"> < span className="currencysymbol">{localStorage.getItem("currency")} </span> {Number(mrp).toFixed(2)}</span>}
                                                </>
                                        }

                                    </div>

                                </div>
                            </div>
                    }
                </div>
                <div className="productcard-rating-carticon">


                    {
                        (wishlist ?
                            <div className={`add-to-cart  ${className1}`} data-toggle="tooltip" data-original-title="Move to Wishlist" onClick={() => clicked('move', productid)} >
                                <div className="productcard-carticon primaryColor" >
                                    <BiSolidCart className="product-card-cart-icon" />
                                </div>
                            </div> :
                            (
                                stock_status != 0 &&
                                <div className={`add-to-cart  ${className1}`} data-toggle="tooltip" data-original-title="Move to Wishlist" onClick={() => handleAddToCart(productid, image, price)} >
                                    <div className="productcard-carticon primaryColor " >
                                        <BiSolidCart className="product-card-cart-icon" />
                                    </div>

                                </div>
                            )

                        )
                    }
                </div>

            </div>
            {/* {
                variantList && variantList.length > 0 && (
                    isDesktoporMobile ?
                        <MobilVarient variantList={variantList} /> :
                        <Varientdropdown variantList={variantList} />
                )
            } */}


        </div >
    );
};

export default CardProduct;














import React, { useContext, useState } from 'react';
import { BsExclamationCircleFill, BsArrowRightCircleFill } from 'react-icons/bs'
import { useSelector, useDispatch } from 'react-redux';
import { IoEyeOutline, IoEyeOffOutline } from "react-icons/io5";
import { MdModeEdit } from "react-icons/md";
import { useLocation } from "react-router-dom";
const InputBox = ({ label, id, handleChange, disabled, change_btn, subcribre, errors, value, currentStep, classname, handleSubmit }) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const openModal = useSelector(state => state.cartdetail.openModal);
    const [showpassword, setShowpassword] = useState(true)
    const [selectedValue, setSelectedValue] = useState(value || "+91")
    const updatedProfilephone = {}
    const setotpformType = ''

    const handleClick = (id) => {
        dispatch({
            type: 'setopenModal',
            payload: !openModal
        });
        dispatch({
            type: 'setotpformType',
            payload: id
        });
        dispatch({
            type: 'setupdateemail',
            payload: 0
        });
        // setotpformType(id)
    }
    const handleInputChange = (e) => {

        dispatch({
            type: 'setslectedphonecode',
            payload: e.target.value
        })
        setSelectedValue(e.target.value); // Update selected value
        handleChange(e); // Call the parent function
    };
    return (
        <>
            {
                disabled ?
                    <>
                        <div className='field-holder'>
                            {
                                id == 'pincode' || id == 'phone' ?
                                    <input type="number" id={id} placeholder={label + '*' + "(Don’t add country code here) "} name={id} defaultValue={updatedProfilephone.hasOwnProperty(id) ? updatedProfilephone[id] : value} className={`custom-input custom-input-disabled ${errors.hasOwnProperty(id) ? 'is-error' : ''}`} />
                                    :
                                    <input type="text" id={id} placeholder={label} name={id} defaultValue={updatedProfilephone.hasOwnProperty(id) ? updatedProfilephone[id] : value} className={`custom-input custom-input-disabled ${errors.hasOwnProperty(id) ? 'is-error' : ''}`} />
                            }
                            {/* <label htmlFor={id} className={`custom-label ${subcribre}  primaryColorcolor`}>{label}</label> */}

                            {
                                change_btn == undefined &&
                                <div className="custom-change-icon " onClick={() => handleClick(id)}>
                                    <div className='email_phone_update secondaryColor'>
                                        <div className='editprofile secondaryColor'><MdModeEdit /></div>
                                    </div>
                                </div>
                            }
                        </div>
                        {errors.hasOwnProperty(id) && <span className='error-text'>{errors[id]}</span>}
                    </>
                    :
                    <>
                        <div className='field-holder ' style={{
                            pointerEvents: currentStep == 1 ? 'none' : '',
                            opacity: currentStep == 1 ? '.5' : '1',
                        }}>
                            {
                                id == 'pincode' || id == 'phone' ?
                                    <div className='d-flex'>
                                        {
                                            ["/register", "/my-account", "/password-reset"].includes(location.pathname) &&

                                            <div className="phone-number-dropdown">
                                                <select id="phone-code" name="phone-code" onChange={handleInputChange}>
                                                    <option value="+91"> +91</option>
                                                    <option value="+971">+971</option>
                                                    <option value="+93">+93</option>
                                                    <option value="+1">+1</option>
                                                    <option value="+44">+44</option>
                                                    <option value="+61"> +61</option>
                                                    <option value="+81"> +81</option>
                                                    <option value="+49">+49</option>
                                                    <option value="+33">+33</option>
                                                    <option value="+55">+55</option>
                                                    <option value="+34">+34</option>
                                                    <option value="+39">+39</option>
                                                    <option value="+7">+7</option>
                                                    <option value="+86">+86</option>
                                                    <option value="+27">+27</option>
                                                    <option value="+54"> +54</option>
                                                    <option value="+52"> +52</option>
                                                    <option value="+20"> +20</option>
                                                    <option value="+62"> +62</option>
                                                    <option value="+63">+63</option>
                                                    <option value="+64"> +64</option>
                                                    <option value="+90">+90</option>
                                                    <option value="+92">+92</option>
                                                    <option value="+880">+880</option>
                                                    {/* You can add more countries here */}
                                                </select>
                                            </div>
                                        }
                                        <input type={'number'} id={id} placeholder={id === 'phone' ? label + '*' + "(Don’t add country code here) " : label} name={id} value={value} className={`custom-input inputnumber ${classname} ${errors.hasOwnProperty(id) ? 'is-error' : ''}`} required onChange={handleChange} />
                                    </div>
                                    :
                                    <input type={id == 'password' && showpassword ? 'password' : "text"} id={id} placeholder={label} name={id} value={value} className={`custom-input ${classname} ${errors.hasOwnProperty(id) ? 'is-error' : ''}`} required onChange={handleChange} />
                            }

                            {/* <label htmlFor={id} className={`custom-label ${subcribre} primaryColorcolor`}>{label}</label> */}
                            {/* {errors.hasOwnProperty(id) && <BsExclamationCircleFill className="error-icon" />} */}
                            {classname == 'input-bg-black' && <BsArrowRightCircleFill className="subsribe-arrow-icon primaryColorcolor" onClick={handleSubmit} />}
                            {
                                id == 'password' &&
                                <div className='passwordeyeicon'> {showpassword ? <IoEyeOffOutline onClick={() => { setShowpassword(false) }} /> : <IoEyeOutline onClick={() => { setShowpassword(true) }} />}</div>
                            }
                        </div>
                        {errors.hasOwnProperty(id) && <span className='error-text'>{errors[id]}</span>}
                    </>
            }
        </>
    );
};

export default InputBox;
import React, { useState, useEffect } from "react";
import validate from "../../validations/FormValidationRules";
import { MdOutlineClose } from "react-icons/md";
import { Col } from "react-bootstrap";
import { InputBox } from "../common";
import useFormValidation from "../../validations/FormValidation";
import MyAccountAction from "../../redux/actions/MyAccountAction";
import { useSelector, useDispatch } from "react-redux";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { auth } from "../../components/firebase/Setup";
import Apis from "../../services/Apis";

const Mobilecheck = () => {
    const dispatch = useDispatch();
    const { verifyotpService } = Apis();
    const mobilecheckstatus = useSelector(state => state.masterdata.mobilecheckstatus);
    const selectedPhoneCode = useSelector(state => state.authdetail.slectedphonecode);
    const openModal = useSelector(state => state.masterdata.openModal);
    const { sendotp } = MyAccountAction();

    const [user, setUser] = useState(null);
    const [errorsotp, setErrorsOtp] = useState({});
    const [recaptchaVerifier, setRecaptchaVerifier] = useState(null);
    const [formValues, setFormValues] = useState({ otp: "", phone: "" });

    const { values, errors, handleChange, handleSubmit, setErrors } = useFormValidation(
        handleNext,
        validate,
        formValues
    );

    // Initialize ReCAPTCHA when component mounts
    useEffect(() => {
        if (!recaptchaVerifier) {
            const verifier = new RecaptchaVerifier(auth, "recaptcha", {
                size: "invisible", // Auto-verifies
                callback: (response) => {
                },
            });
            setRecaptchaVerifier(verifier);
        }
    }, [dispatch, recaptchaVerifier]);

    async function handleOTPVerification() {
        if (mobilecheckstatus === 0) {
            // Send OTP
            try {
                if (!recaptchaVerifier) {
                    return;
                }
                const phoneNumberWithCode = `${selectedPhoneCode}${values.phone}`;
                const confirmation = await signInWithPhoneNumber(auth, phoneNumberWithCode, recaptchaVerifier);
                dispatch({
                    type: "setmobilecheckstatus",
                    payload: 1
                });
                setUser(confirmation);
            } catch (error) {
                setErrorsOtp({ otp: "Failed to send OTP. Please try again." });
            }
        } else {
            // Verify OTP
            if (user) {
                try {
                    const confirmationResult = await user.confirm(values.otp); // Verify entered OTP
                    if (confirmationResult) {
                        dispatch({ type: "setupdatephoneno", payload: values.phone })
                        // Close modal on successful OTP verification
                        dispatch({ type: 'setopenModal', payload: false });

                        // You can dispatch an action here to update user authentication status
                    } else {
                        setErrorsOtp({ otp: "Invalid OTP. Please try again." });
                    }
                } catch (err) {
                    if (err.code === "auth/invalid-verification-code") {
                        setErrorsOtp({ otp: "Invalid OTP. Please try again." });
                    } else {
                    }
                }
            } else {
                setErrorsOtp({ otp: "Something went wrong. Please request a new OTP." });
            }
        }
    }

    // Ensure form submission calls handleNext()
    function handleNext() {
        handleOTPVerification();
    }

    
    return (
        <div className="custom-modal-component">
            <div className="custom-modal-header">
                <div className="modal-header-title">
                    {
                        mobilecheckstatus === 0 ?
                        "Enter New Mobile Number" :
                        "Enter OTP"
                    }
                    </div>
                <div
                    className="custom-modal-closeicon"
                    onClick={() => dispatch({ type: "setopenModal", payload: !openModal })}
                >
                    <MdOutlineClose size={20} />
                </div>
            </div>
            {mobilecheckstatus === 0 ? (
                <InputBox label="Phone" id="phone" handleChange={handleChange} errors={errors} value={values.phone} />
            ) : (
                <>
                    <InputBox label="Enter OTP" id="otp" handleChange={handleChange} errors={errors} value={values.otp} />
                    <div className="errorotp">{errorsotp.otp}</div>
                </>
            )}
            {/* CAPTCHA will now appear above the button */}
            <Col lg={12} className="captcha-div">
                <div id="recaptcha"></div>
            </Col>

            <div className="product-detail-buttongroup send_otp_btn" onClick={handleSubmit}>
                <div className="product-detail-button primaryColor">
                    {mobilecheckstatus === 0 ? "Send OTP for Verification" : "Confirm OTP"}
                </div>
            </div>
        </div>
    );
};

export default Mobilecheck;

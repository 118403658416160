import React, { useContext, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';

import { Container, Row, Col, Modal } from 'react-bootstrap';
import { ProfileForm, OtpForm, MyAccountComp } from '../components/common';
import { FaAngleRight } from "react-icons/fa6";
import { NavLink } from 'react-router-dom';
import MasterAction from '../redux/actions/MasterAction';
import { useSelector, useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import Mobilecheck from '../components/common/Mobilecheck';
const Myaccount = () => {
    const isDesktopOrMobile = useMediaQuery({ query: '(max-width: 991px)' })

    const dispatch = useDispatch()
    useEffect(() => {
        dispatch({
            type: 'setHeadercategory',
            payload: false,
        });
    }, []);
    const openModal = useSelector(state => state.authdetail.openModal);
    const otpformType = useSelector(state => state.masterdata.otpformType);
    const handleClose = () => {
        dispatch({
            type: 'setopenModal', payload: !openModal
        })
        dispatch({
            type: 'setotpform', payload: 0
        })
    }

    const { getProfileData } = MasterAction();
    useEffect(() => {
        dispatch(getProfileData())
    }, [])



    return (
        <Container>
            <Helmet>
                <title>{localStorage.getItem("title")} - Myaccount</title>
                <link rel="canonical" href={window.location.origin + window.location.pathname} />
                {/* <GoogleAnalytics trackingId={"G-ZDTRF1HJ43"}/> */}
            </Helmet>
            <Row className='custom-margin-top'>
                {/* <div className='d-flex'>
                        <div className='accout_breadcomp1'>
                            <NavLink className="text-decoration-none" to={'/my-account'}>
                                Your Account <FaAngleRight />
                            </NavLink>
                        </div>
                        <div className='accout_breadcomp2 primaryColorcolor'>
                            {window.location.pathname.slice(1)}
                        </div>
                    </div>
                    <h3 className='Youraddress_title pt-3'>
                        Your Account
                    </h3> */}
                {
                    !isDesktopOrMobile &&
                    <>
                        <Col lg={5}>
                            <ProfileForm />
                        </Col>
                        {/* <Col lg={2}>

                        </Col> */}
                    </>
                }
                <Col lg={6} sm={12} xs={12}>
                    <MyAccountComp />
                </Col>
            </Row>
            <Modal
                show={openModal}
                size="sm"
                className='my-custom-modal'
                centered
                onHide={() => handleClose()}
            >
                <Modal.Body>
                    {
                       otpformType == 'phone' ? 
                     
                    <Mobilecheck/>:
                    <OtpForm />
                    }
                    
                </Modal.Body>
            </Modal>
        </Container>
    );
};

export default Myaccount;
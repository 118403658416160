import React, { useMemo, useState, useEffect } from 'react';
import { Row, Container, Col } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useMediaQuery } from 'react-responsive';
import { GiRunningShoe } from "react-icons/gi";
import { HomeCarousel, HomeCard, HomeCarouselCardComponent, Twobanner, Bannercategories, MostViewed, Homethreebanner, BackgroundimagCarousel, SingleCarousel, Blogs, Clientsdata, Ourproductcomp, HomepageBrands, FourBanner, HomepageCarousel, ImageCard, Banner41, MobileOnecard8banner, ThreeCategoryBanners, Banner1, Banner3, Banner2, HomeComponenet4, Banner8, Brand3, MultipleTabComponenet, GoogleMap, HomeCategorySlider, Popularcomp, Brand4, About, ContactForm, SelectedCategory } from '../components/common';
import { IoMdArrowDropdown } from "react-icons/io";
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { MdChevronRight } from "react-icons/md";
import logo from '../images/bg_image.webp';
import MasterAction from '../redux/actions/MasterAction';
// import Banner2 from '../components/common/Banner2';
const Home = React.memo(() => {
    const dispatch = useDispatch()
    const isDesktopOrMobile = useMediaQuery({ query: '(max-width: 768px)' })
    const isDesktopOrMobile1 = useMediaQuery({ query: '(min-width: 768px)' })
    const isMobile = useMediaQuery({ query: `(max-width: 991px)` });

    const HomePageData = useSelector(state => state.masterdata.HomePageData);
    const Headercategory = useSelector(state => state.masterdata.Headercategory);

    const fullcarouselapidata = useSelector(state => state.masterdata.fullcarouselapidata);
    const fourproductcardapidata = useSelector(state => state.masterdata.fourproductcardapidata);
    const productloadmoredata = useSelector(state => state.masterdata.productloadmoredata)
    const apicarouseldata = useSelector(state => state.masterdata.apicarouseldata);
    const fourproductcarddata = useSelector(state => state.masterdata.fourproductcarddata);
    const productloadmoreapidata = useSelector(state => state.masterdata.productloadmoreapidata);
    const multicarouseldata = useSelector(state => state.masterdata.multicarouseldata);
    const normalloader = useSelector(state => state.masterdata.normalloader);
    const categoryData = useSelector(state => state.masterdata.categoryData);
    const selectedmultiplecarousel = useSelector(state => state.masterdata.selectedmultiplecarousel);
    const [loadmoredata, setloadmoredata] = useState(productloadmoreapidata?.length > 0 ? productloadmoreapidata : []);
    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch({
            type: 'setHeadercategory',
            payload: true
        });
    }, [HomePageData]);
    //...................................looadmore logic
    useEffect(() => {
        if (productloadmoreapidata?.length > 0) {
            // Spread both loadmoredata and productloadmoreapidata arrays correctly
            setloadmoredata(prevData => [...prevData, ...productloadmoreapidata]);
        }
    }, [productloadmoreapidata]);
    const { Carouselapidatalist, Multifullcarouseldatalist, fourproductcardlist, productloadmorelist, getDeliveryChargesdata } = MasterAction();
    const [selectedData, setSelectedData] = useState(null);
    const [activeHeading, setActiveHeading] = useState(null);
    const [position, setpositon] = useState(Math.floor(Math.random() * 100000000));
    const [selectedButton, setSelectedButton] = useState(0);
    const [loadmorebtn, setloadmorebtn] = useState(1)
    const [selectedopen, setSelectedopen] = useState(true);
    const handleClick = (index, item) => {
        const clickedData = HomePageData[0].sectionList.filter(comp => comp.component === 'full-carousel-products').find((item, idx) => idx === index);
        setSelectedData(clickedData);
        setSelectedButton(index);
        setSelectedOption(item);
        setSelectedopen(false)
    };
    var comps = [];
    const [selectedOption, setSelectedOption] = useState();
    //.....................full carousel logic
    useMemo(() => {
        if (apicarouseldata.component_type == "dynamic" && apicarouseldata.page_component_type == "dynamic") {

            const input_data = {

                filter_by: apicarouseldata.filter_by,
                limit: apicarouseldata.limit,
                // category_ids: apicarouseldata.category_ids
            }
            dispatch(Carouselapidatalist(input_data, apicarouseldata.api_url))
        }
    }, [apicarouseldata])
    // //.............................four products popular
    useMemo(() => {
        if (fourproductcarddata.component_type == "dynamic" && fourproductcarddata.page_component_type == "dynamic") {

            const input_data = {

                filter_by: fourproductcarddata.filter_by,
                limit: fourproductcarddata.limit,
            }
            dispatch(fourproductcardlist(input_data, fourproductcarddata.api_url))
        }
    }, [fourproductcarddata])
    // //...............................................looadmore
    useMemo(() => {
        if (productloadmoredata.component_type == "dynamic" && productloadmoredata.page_component_type == "dynamic") {

            const input_data = {

                filter_by: productloadmoredata.filter_by,
                limit: productloadmoredata.limit,
                page: loadmorebtn,
            }
            dispatch(productloadmorelist(input_data, productloadmoredata.api_url))
        }
    }, [productloadmoredata, loadmorebtn])

    useMemo(() => {
        // Ensure multicarouseldata.sectionData is an array before mapping
        // for initially api call for multiplecarousel
        if (Array.isArray(multicarouseldata.sectionData)) {
            if (multicarouseldata.sectionData[0].result_type === "dynamic") {
                const input_data = {
                    category_ids: multicarouseldata.sectionData[0].category_ids,
                };
                dispatch(Multifullcarouseldatalist(input_data, multicarouseldata.sectionData[0].api_url));
            }
            setActiveHeading(multicarouseldata.sectionData[0].category_ids);
        }
    }, [multicarouseldata]);

    const multiplecarouselclick = (id, apiurl) => {
        setpositon(Math.floor(Math.random() * 100000000))
        const input_data = {
            category_ids: id,
        };
        setActiveHeading(id);

        // alreay current api will not col
        if (activeHeading !== id) {
            // onclick api call
            dispatch(Multifullcarouseldatalist(input_data, apiurl));
        }
    }
    useEffect(() => {
        dispatch(getDeliveryChargesdata())
    }, [])
    useEffect(() => {
        // Set initial selected data if needed
        if (HomePageData.length > 0) {
            var filteredData = HomePageData[0].sectionList.filter(comp => comp.component === 'full-carousel-products');
            setSelectedData(filteredData.length > 0 ? filteredData[0] : null);
            setSelectedOption(comps[0])
        }
    }, [HomePageData]);
    return (
        <div className='homepage-top-space'   >
            <Container className='home-custom-container'>
                <Helmet>
                    <title>{localStorage.getItem("title")} - {localStorage.getItem("domain_title")} </title>
                    {/* <GoogleAnalytics trackingId={"G-ZDTRF1HJ43"}/> */}
                </Helmet>


                {
                    HomePageData.length > 0 ?
                        <>
                            {


                                HomePageData[0].sectionList.map((comp, index) => {

                                    // if (comp.component == 'main-slider') {
                                    //     return (
                                    //         <HomeCarousel data={comp} key={index} />
                                    //     )
                                    // }

                                })
                            }
                            <div className={!isDesktopOrMobile1 ? "" : "custom-card-main"}>
                                <div className="home-card-component">
                                    {
                                        HomePageData && HomePageData.length > 0 &&
                                        HomePageData[0].sectionList &&
                                        HomePageData[0].sectionList.map((comp, index) => {
                                            if (comp.component == 'card-component') {
                                                return (
                                                    <Container fluid>
                                                        <Row key={index}>
                                                            {
                                                                comp.sectionResult.map((ele, subindex) => {
                                                                    return (

                                                                        ele.result_type == 'static' ?
                                                                            <Col xl={ele.space_col} lg={ele.space_col} md={ele.space_col} key={subindex}>
                                                                                <div className='home-halfbanner'>
                                                                                    <NavLink to={ele.url}>
                                                                                        <img referrerPolicy="no-referrer" src={ele.card_image}
                                                                                            onError={e => {
                                                                                                e.currentTarget.src = logo
                                                                                            }} />
                                                                                    </NavLink>
                                                                                </div>
                                                                            </Col>
                                                                            :
                                                                            <Col xl={ele.space_col} lg={ele.space_col} md={ele.space_col} sm={6} xs={6} key={subindex}>
                                                                                <HomeCard data={ele} />
                                                                            </Col>
                                                                    )
                                                                })
                                                            }
                                                        </Row>
                                                    </Container>
                                                )
                                            }

                                            // if (comp.component == 'full-banner') {
                                            //     var bannerimage = comp.sectionResult[0].image;
                                            //     var mobileimage = comp.sectionResult[0].mobile_image;
                                            //     return (
                                            //         <div className='home-fullbanner mb-2 ' key={index}>
                                            //             {
                                            //                 isMobile ?
                                            //                     <img referrerPolicy="no-referrer" src={mobileimage} onError={e => {
                                            //                         e.currentTarget.src = logo
                                            //                     }} /> :
                                            //                     <img referrerPolicy="no-referrer" src={bannerimage} onError={e => {
                                            //                         e.currentTarget.src = logo
                                            //                     }} />
                                            //             }



                                            //         </div>
                                            //     )
                                            // }
                                            // if (comp.component == 'half-carosal-component') {
                                            //     return (
                                            //         <div className='home-carousel-bg-wihte mb-2' key={index}>
                                            //             {
                                            //                 !isMobile ?
                                            //                     <HomeComponenet4 data={comp.sectionResult} classname={"half_carousel_img"} btn={"onecard"} />
                                            //                     : <MobileOnecard8banner data={comp.sectionResult} classname={"half_carousel_img"} />
                                            //             }

                                            //         </div>
                                            //     )
                                            // }

                                            // if (comp.component == 'full-carousel-products') {
                                            //     return (
                                            //         <div className='home-carousel-bg-wihte mb-2' key={index}>
                                            //             <HomeCarouselCardComponent showbuttons={true} position={1} loopindex={index} carouselCompData={comp} type={"view-all"} />
                                            //         </div>
                                            //     )
                                            // }
                                            // if (comp.component == 'four-banners') {
                                            //     return (
                                            //         <div className='home-carousel-bg-wihte mb-2' key={index}>
                                            //             <Brand4 carouselCompData={comp} />
                                            //         </div>
                                            //     )
                                            // }
                                            // if (comp.component == 'double-banner') {
                                            //     return (
                                            //         <div className='home-carousel-bg-wihte mb-2' key={index}>
                                            //             <Banner2 carouselCompData={comp} />
                                            //         </div>
                                            //     )
                                            // }
                                            // if (comp.component == 'three-banners') {
                                            //     return (
                                            //         <div className='home-carousel-bg-wihte mb-2' key={index}>
                                            //             <Banner3 carouselCompData={comp} />
                                            //         </div>
                                            //     )
                                            // }
                                            // if (comp.component == 'three-banners-V2') {
                                            //     return (
                                            //         <div className='home-carousel-bg-wihte mb-2' key={index}>
                                            //             <Banner3 carouselCompDataV2={comp} />
                                            //         </div>
                                            //     )
                                            // }
                                            // if (comp.component == 'three-banners-V3') {
                                            //     return (
                                            //         <div className='home-carousel-bg-wihte mb-2' key={index}>
                                            //             <Banner3 carouselCompDataV3={comp} />
                                            //         </div>
                                            //     )
                                            // }
                                            // // if (comp.component == 'full-banner') {
                                            // //     return (
                                            // //         <div className='home-carousel-bg-wihte mb-2' key={index}>
                                            // //             <Banner1 carouselCompData={comp}/>
                                            // //         </div>
                                            // //     )
                                            // // }
                                            // if (comp.component == 'four-banners-v2') {
                                            //     return (
                                            //         <div className='home-carousel-bg-wihte mb-2' key={index}>
                                            //             <Banner41 carouselCompData={comp} />
                                            //         </div>
                                            //     )
                                            // }

                                            // if (comp.component == 'leftimg-rightcont-component') {
                                            //     return (
                                            //         <div className='home-carousel-bg-wihte mb-2' key={index}>
                                            //             <About data={comp.sectionResult[0]} />
                                            //         </div>
                                            //     )
                                            // }
                                            // if (comp.component == 'leftcont-rightimg-component') {
                                            //     return (
                                            //         <div className='home-carousel-bg-wihte mb-2' key={index}>
                                            //             <ImageCard data={comp.sectionResult[0]} />
                                            //         </div>
                                            //     )
                                            // }

                                            // .................................................Home slider
                                            if (comp.component == 'main-slider') {
                                                return (
                                                    <div className='homepage-components'>
                                                        {
                                                            comp &&
                                                            <Row>
                                                                {
                                                                    !isMobile && Headercategory &&
                                                                    <Col xl={3} lg={4} md={1} sm={0} xs={1}>
                                                                    </Col>
                                                                }

                                                                <Col xl={Headercategory ? 9 : 12} lg={Headercategory ? 8 : 12} md={12}>
                                                                    <HomepageCarousel slider_data={comp} key={index} position={0} />
                                                                </Col>
                                                            </Row>


                                                        }
                                                    </div>
                                                )
                                            }
                                            //  .................................................threeBanner
                                            if (comp.component == 'threeBanner-component') {
                                                return (
                                                    <Container>
                                                        <div className='homepage-components'>
                                                            {
                                                                comp.sectionResult.length > 0 &&

                                                                <Homethreebanner data={comp.sectionResult.slice(0, 3)} />
                                                            }
                                                        </div>
                                                        {/* <div className='space-30'></div> */}
                                                    </Container>
                                                )
                                            }
                                            // ......................................Carousel with background images
                                            if (comp.component == 'carousel-with-background-image') {
                                                return (
                                                    <div className='homepage-components'>
                                                        {
                                                            comp.sectionResult.length > 0 &&

                                                            <BackgroundimagCarousel backgrounddata={comp.sectionResult[0]} position={1} />
                                                        }
                                                    </div>
                                                )
                                            }
                                            // ..................................................two Banners
                                            if (comp.component == 'twoBanner-component') {
                                                return (
                                                    <>
                                                        {
                                                            comp.sectionResult.length > 0 &&
                                                            <div className='homepage-components'>
                                                                <Twobanner data={comp.sectionResult.slice(0, 2)} />
                                                            </div>
                                                        }

                                                    </>
                                                )
                                            }

                                            // .................................................Full Carousel Blogs
                                            if (comp.component == 'full-carousel-Blogs') {
                                                return (
                                                    <Container className='space-bottom-20'>
                                                        <div className='homepage-components'>
                                                            {
                                                                comp.sectionResult.length > 0 &&
                                                                <Blogs position={5} Blog={true} fullcarouselBlogdata={comp.sectionResult} Blogheading={comp.section_title} blogdiscription={comp.description} />
                                                            }

                                                        </div>
                                                    </Container>
                                                )
                                            }
                                            // ..................................................testmonial
                                            if (comp.component == 'testmonial') {
                                                return (
                                                    <>
                                                        <div className='homepage-components'>
                                                            {
                                                                comp.sectionResult.length > 0 &&

                                                                <Clientsdata
                                                                    haedingdata={comp.sectionResult[0]}
                                                                    sliderdata={comp.sectionResult && comp.sectionResult.length > 0 ? comp.sectionResult.slice(1) : []}
                                                                />
                                                            }
                                                        </div>
                                                        <div className='space-30'></div>
                                                    </>
                                                )
                                            }


                                            // .......................................................categorycomp

                                            if (comp.component === 'category-component') {
                                                if (comp.hasOwnProperty('component_size')) {
                                                    if (comp.component_size === 'small') {
                                                        return (
                                                            <Container>
                                                                <div className='homepage-components'>
                                                                    {comp.sectionResult.length > 0 && (
                                                                        <SingleCarousel categorydata={comp.sectionResult} position={4} add={true} />
                                                                    )}
                                                                </div>
                                                            </Container>
                                                        );
                                                    }

                                                    if (comp.component_size === 'large') {
                                                        return (
                                                            <Container fluid>
                                                                <div className='pt-3'>
                                                                    <HomeCategorySlider categoryData={comp.sectionResult} />
                                                                </div>
                                                            </Container>
                                                        );
                                                    }
                                                } else {
                                                    // Consider handling this differently instead of an alert
                                                    return (
                                                        <Container>
                                                            <>
                                                                {comp.sectionResult.length > 0 &&
                                                                    <div className='homepage-components'>
                                                                        <SingleCarousel categorydata={comp.sectionResult} position={4} add={true} />
                                                                    </div>
                                                                }

                                                            </>
                                                        </Container>
                                                    );
                                                }
                                            }

                                            // Optional: Add a fallback return for when comp.component is not 'category-component'
                                            // return null or some other component


                                            // .................................................Full Carousel Products

                                            if (comp.component == 'full-carousel-products') {
                                                if (comp.component_type == "dynamic" && comp.page_component_type == "dynamic") {
                                                    dispatch({
                                                        type: "setapicarouseldata",
                                                        payload: comp
                                                    })
                                                    return (
                                                        <div className='homepage-components'>
                                                            {
                                                                fullcarouselapidata &&
                                                                fullcarouselapidata.length > 0 && comp.component == 'full-carousel-products' &&

                                                                <SingleCarousel single={true} carouseldata={fullcarouselapidata} viewall={comp.button_label} api_url={comp.api_url} button_url={comp.url} heading={comp.section_title} position={Math.floor(Math.random() * 100000000)} description={comp.description} category_ids={comp.category_ids} />
                                                            }
                                                        </div>
                                                    )
                                                }
                                                else {
                                                    return (

                                                        <div className='homepage-components'>
                                                            {
                                                                comp.sectionResult &&
                                                                comp.sectionResult.display_items && comp.sectionResult.display_items.products && comp.sectionResult.display_items.products.length > 0 &&

                                                                <SingleCarousel single={true} carouseldata={comp.sectionResult.display_items.products} viewall={comp.button_label} api_url={comp.api_url} button_url={comp.url} heading={comp.section_title} position={Math.floor(Math.random() * 100000000)} description={comp.description} category_ids={comp.category_ids} />
                                                            }
                                                        </div>
                                                    )
                                                }
                                            }
                                            ///.........................................................................................product-4-card
                                            if (comp.component == 'product-4-card') {
                                                if (comp.component_type == "dynamic" && comp.page_component_type == "dynamic") {
                                                    dispatch({
                                                        type: "setfourproductcarddata",
                                                        payload: comp
                                                    })
                                                }
                                                return (
                                                    <div className={``}>
                                                        {
                                                            <Popularcomp data={fourproductcardapidata} heading={comp.section_title} viewall={comp.button_label} button_url={comp.url} />
                                                        }
                                                    </div>
                                                )
                                            }

                                            //.....................................................................productload more
                                            if (comp.component == 'product-load-more') {
                                                if (comp.component_type == "dynamic" && comp.page_component_type == "dynamic") {
                                                    dispatch({
                                                        type: "setproductloadmoredata",
                                                        payload: comp
                                                    })
                                                }
                                                return (
                                                    <>
                                                        <MostViewed data={loadmoredata} heading={comp.section_title} viewall={comp.button_label} button_url={comp.url} />
                                                        {
                                                            productloadmoreapidata.length > 0 &&
                                                            <div className='loadmore-main'>
                                                                <div className='mostpopularloadmore_button' onClick={() => { setloadmorebtn(loadmorebtn + 1) }}>Load More
                                                                   
                                                                </div>
                                                                {
                                                                    normalloader &&
                                                                    <div class="spinner-border spinner-border-sm ms-1" role="status">
                                                                </div>
                                                                }
                                                              
                                                            </div>
                                                        }
                                                    </>
                                                )
                                            }
                                            //..................................................................Multiple Full Carousel

                                            // <div className='space-30'></div>
                                            {

                                                if (comp.component == 'multiple-full-carousel') {
                                                    if (comp.component_type == 'dynamic' && comp.page_component_type == 'dynamic') {
                                                        dispatch({
                                                            type: "setmulticarouseldata",
                                                            payload: comp
                                                        })
                                                        return (
                                                            <>
                                                                <div className={`main-ourproducts `}>
                                                                    {/* <div className='space-40'></div> */}
                                                                    {
                                                                        comp.sectionData && comp.sectionData.length > 0 &&
                                                                        comp.sectionData.map((ele) => {
                                                                            // Find the corresponding category data based on category_ids
                                                                            const data = categoryData.find(item => item.id === ele.category_ids);
                                                                            return (
                                                                                <div key={ele.category_ids}>
                                                                                    <div>
                                                                                        <div
                                                                                            onClick={() => multiplecarouselclick(ele.category_ids, ele.api_url)}
                                                                                            className={`ourprodutscat ${activeHeading === ele.category_ids ? 'primaryColor' : ''}`}
                                                                                        >
                                                                                            {/* {
                                                                                                data &&
                                                                                                <div className="multiplecarouseltitle">
                                                                                                    <img className='multicarouselimage' src={localStorage.getItem("cdn_domain") + (data ? data.image : '')} />
                                                                                              </div>
                                                                                            } */}
                                                                                            {
                                                                                                ele.carosal_image != "" &&
                                                                                                <div className="multiplecarouseltitle">
                                                                                                    {/* Ensure the image source is valid */}
                                                                                                    <img className='multicarouselimage' src={ele.carosal_image} />
                                                                                                </div>
                                                                                            }



                                                                                            <div className="mutiplecarouseltitlesname">
                                                                                                {ele.carosal_heading}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        })
                                                                    }

                                                                    {isDesktopOrMobile1 &&
                                                                        comp.sectionData &&
                                                                        comp.sectionData.length > 0 &&
                                                                        comp.sectionData
                                                                            .filter(ele => activeHeading === ele.category_ids) // Filter for the active element
                                                                            .map(ele => (  // Map over the filtered result and return JSX
                                                                                <NavLink to={ele.url} className="text-decoration-none">
                                                                                    <h6 key={ele.category_ids} className="Multiplecarouselviewall home-carousel-componentheader-viewall">{ele.button_label}</h6>
                                                                                </NavLink>
                                                                            ))
                                                                    }
                                                                </div>
                                                                <div className='homepage-components'>
                                                                    {

                                                                        selectedmultiplecarousel && selectedmultiplecarousel.length > 0 &&

                                                                        <SingleCarousel key={activeHeading} carouseldata={selectedmultiplecarousel} viewall={comp.button_label} button_url={comp.url} heading={"noheading-for-multiplecarousel"} position={activeHeading} description={comp.description} mobileversion={"multiplecarousel-mobileversion"} />
                                                                    }
                                                                </div>
                                                            </>
                                                        )
                                                    }

                                                }
                                            }


                                            <div className='space-30'></div>
                                            //......................................................................Brands
                                            if (comp.component == 'Selected-category') {
                                                return (
                                                    <Container>
                                                        <div className='space-30'></div>
                                                        <div className='homepage-components'>
                                                            {
                                                                comp.sectionResult.length > 0 &&
                                                                <HomepageBrands position={2} Brandsdata={comp.sectionResult} />
                                                            }
                                                        </div>
                                                        <div className='space-30'></div>
                                                    </Container>
                                                )
                                            }

                                            //......................................................................single Banner
                                            if (comp.component == 'singlebanner-component') {
                                                return (
                                                    <div className='homepage-components'>
                                                        {
                                                            comp.sectionResult.length > 0 &&

                                                            <Banner1 singlebannerdata={comp.sectionResult[0]} />
                                                        }
                                                    </div>
                                                )
                                            }
                                            //......................................................................four Banner
                                            if (comp.component == 'fourBanner-component') {
                                                return (
                                                    <div className={`space-20 space-30`}>
                                                        {
                                                            comp.sectionResult.length > 0 &&

                                                            <FourBanner fourbannerdata={comp.sectionResult} />
                                                        }
                                                    </div>
                                                )
                                            }

                                            //.....................................................................Five banner category
                                            if (comp.component == 'Banner-category') {
                                                return (
                                                    <div className={`space-20 space-30`}>
                                                        {
                                                            comp.sectionResult.length > 0 &&

                                                            <Bannercategories bannercatdata={comp.sectionResult} />
                                                        }
                                                        <div className={` space-30 `}>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                            <div className='space-30'></div>
                                        })
                                    }

                                    {/* <ThreeCategoryBanners/> */}
                                    {/* <div><Brand3/></div>
                                    <div><Banner8/></div>
                                    <div><About/></div>
                                    <div><MapAddress/></div> 
                                    <div><GoogleMap/></div>  */}
                                    {/* <div><ContactForm/></div> */}
                                </div>

                            </div>

                        </>
                        :
                        <div className='custom-height'></div>
                }

            </Container>
        </div>
        // <HomePlaceholder />
    );
});

export default Home;



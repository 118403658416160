import Apis from "../../services/Apis";
import Cookies from 'universal-cookie';
import { notify } from '../../components/common';
import { useSelector, useDispatch } from "react-redux";
import jwtDecode from "jwt-decode";
import CartAction from "./CartAction";
import MasterAction from "./MasterAction";
import { useNavigate,useSearchParams } from "react-router-dom";

const AuthAction = () => {
  const cookies = new Cookies();
  const navigate = useNavigate()

  const { getCartList } = CartAction()
  const { getMasterData } = MasterAction()

  const apidispatch = useDispatch()
  const { SignUpService, LoginService ,othersourcelogindata,updatepwdService} = Apis();

  const SignUp = (input_data, setErrors, setcurrentStep) => async dispatch => {
    dispatch({
      type: 'setloader',
      payload: true
    });
    const response = await SignUpService(input_data);
    if (response.data.status == "success") {
      notify('add2cart', response.data.message, false)
      navigate('/login')
     
      dispatch({
        type: 'setloader',
        payload: false
      });
    } else {
      setcurrentStep(0)
      setErrors({
        [response.data.type]: response.data.message
      })
      notify('warning', response.data.message, false)
      dispatch({
        type: 'setloader',
        payload: false
      });
    }
  };


  const updatepwd = (input_data) => async dispatch => {
    dispatch({
      type: 'setloader',
      payload: true
    });
    const response = await updatepwdService(input_data);
    if (response.status == 200) {
      if (response.data.status == "success") {
        dispatch({
          type: 'setloader',
          payload: false
        });

        dispatch({
          type: 'setStep',
          payload: 3
        });
        navigate("/login");
      } else {
        dispatch({
          type: 'setloader',
          payload: false
        });

      }
    }

  };
  const LoginApi = (input_data, errors, setErrors) => async dispatch => {
    dispatch({
      type: 'setloader',
      payload: true
    });
    const response = await LoginService(input_data);
    if (response.data.status == "success") {
      navigate('/')
      cookies.set("jwt_token", response.data.jwt_token, { path: '/' });
      const userData = jwtDecode(cookies.get("jwt_token"));
      dispatch({
        type: 'setauthToken',
        payload: response.data.jwt_token
      });
    
      dispatch({
        type: 'setauthStatus',
        payload: true
      });
      dispatch({
        type: 'setuserData',
        payload: userData
      });
      apidispatch(getCartList())
      apidispatch(getMasterData())
      dispatch({
        type: 'setloader',
        payload: false
      });
     
    } else {
      setErrors({
        [response.data.type]: response.data.msg
      })
      // errors[response.data.type] = response.data.msg;
      notify('warning', response.data.msg, false)
      dispatch({
        type: 'setloader',
        payload: false
      });
    }
  };
  const othersourcelogindataapi = (input_data) => async dispatch => {

    const response = await othersourcelogindata(input_data);
    if (response.data.status == "success") {
      navigate('/')
      cookies.set("jwt_token", response.data.jwt_token, { path: '/' });
      const userData = jwtDecode(cookies.get("jwt_token"));
      dispatch({
        type: 'setauthToken',
        payload: response.data.jwt_token
      });
    
      dispatch({
        type: 'setauthStatus',
        payload: true
      });
      dispatch({
        type: 'setuserData',
        payload: userData
      });
      apidispatch(getCartList())
      apidispatch(getMasterData())
   
     
    } else {
      // setErrors({
      //   [response.data.type]: response.data.msg
      // })
      // errors[response.data.type] = response.data.msg;
      notify('warning', response.data.msg, false)
    
    }
  };

  return {
    LoginApi: LoginApi,
    SignUp: SignUp,
    othersourcelogindataapi:othersourcelogindataapi,
    updatepwd:updatepwd,
  }
};

export default AuthAction;

import React, { useContext, useState } from 'react';
import { MdOutlineClose } from 'react-icons/md'
import { ProfileForm, InputBox } from '../common';
import { StateContext } from '../context/ManageStates';
import validate from "../../validations/FormValidationRules";
import useFormvalidation from '../../validations/FormValidation'
// import { useAccount } from '../../hooks';
import MyAccountAction from "../../redux/actions/MyAccountAction"
import OrderAction from '../../redux/actions/OrderAction';
import Apis from '../../services/Apis';
import { useSelector, useDispatch } from 'react-redux';
const OtpForm = () => {
    // const { setopenModal,otpformType, openModal, otpform } = useContext(StateContext)
    const dispatch = useDispatch()
    const otpformType = useSelector(state => state.masterdata.otpformType);
    const openModal = useSelector(state => state.masterdata.openModal);
    const otpform = useSelector(state => state.masterdata.otpform);
    const updateemail = useSelector(state => state.authdetail.updateemail);
    const profileemailnew = useSelector(state => state.authdetail.profileemailnew);
    const emailotp = useSelector(state => state.orderdetail.emailotp);
    const { sendotp } = MyAccountAction();
    const { checkemail, verifyotpServiceNewprofile } = OrderAction();
    const [errorsotp, setErrorsotp] = useState({});
    const [formValues, setFormValues] = useState(

        {
            email: "",
            otp: "",
        }
    )
    const { verifyotpServiceNew } = Apis()
    const { values, errors, handleChange, handleSubmit, handlePictureChange } = useFormvalidation(
        handleNext,
        validate,
        formValues
    );
    const close =()=>{
        dispatch({
            type: 'setopenModal', payload: !openModal
        })
        dispatch({
            type: 'setupdateemail',
            payload: 0
        });
    }

    function handleNext() {
        // if (otpform !== 0 && (!values.otp || values.otp.trim() === '')) {
        //     setErrorsotp({ otp: 'OTP is required' });

        //     return; // Stop execution if validation fails
        // }
        // else {
        //     sendotp(values, errors)
        // }
        // if (otpform == 0) {
        //     sendotp(values, errors)
        // }
        var input_data = {
            email: values.email,
            phone: values.phone
        }
        if (updateemail === 0) {
            dispatch(checkemail(input_data))
        }
        else {
            var input_data1 = {
                email: values.email,
                otp: values.otp,
                id: emailotp.data,
            }
            dispatch(verifyotpServiceNewprofile(input_data1))
        }
            // dispatch({
            //     type: "setprofileupdatemail",
            //     payload: values.email
            // })

            dispatch({
                type: "setstoreemail",
                payload: values.email
            })

        // Login(values, errors)
    }

    return (
        <div className='custom-modal-component'>
            <div className='custom-modal-header'>
                <div className='modal-header-title'>
                    {
                         updateemail == 0 ?

                        "Enter New Email Address":
                        "Enter OTP"

                    }
                </div>
                <div className='custom-modal-closeicon'
                    onClick={() =>
                     close()
                    }
                >
                    <MdOutlineClose size={"20"} />
                </div>
            </div>
            {
                updateemail == 0
                    ?


                    <InputBox label={"Email"} id={"email"} key={otpform} handleChange={handleChange} errors={errors} />

                    :
                    <>
                        <InputBox label={"Enter Otp"} id={"otp"} key={otpform} handleChange={handleChange} errors={errors} value={values.otp} />

                        <div className='errorotp'> {errorsotp.otp}</div>
                    </>
            }

            <div className='product-detail-buttongroup send_otp_btn' onClick={handleSubmit}>
                <div className={`product-detail-button primaryColor `}>

                    {
                        otpform == 0 ?
                            " Send OTP for verification"
                            :
                            "Confirm Otp"
                    }
                </div>
            </div>
        </div>
    );
};

export default OtpForm;


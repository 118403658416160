import React, { useContext, useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { ProductVariants, ReviewRatingSection, CrosSellList, Vochers, CrosSellListMobile, BreadComp, CartSidebar } from '../components/common';
// import { Markup } from 'interweave';
import Dropdown from 'react-bootstrap/Dropdown';
import ProductAction from "../redux/actions/ProductAction";
import { BsTruck } from "react-icons/bs";
import CartAction from "../redux/actions/CartAction";
import MasterAction from '../redux/actions/MasterAction';
import { ProductDetailGallery, ProductDetailSwiperMobile, HomeCarouselCardComponent, SingleCarousel, RatingNew, DetailBankOffers, Bundles, Addons, HomeCarouselCardComponentDup, CarouselComp2 } from '../components/common';
import { useMediaQuery } from "react-responsive";
import ProductJsonData from '../components/data/ProductJsonData';
import { NavLink, useParams } from 'react-router-dom';
import { IoCheckmarkCircleSharp } from "react-icons/io5";
import { IoCloseOutline } from "react-icons/io5";
import { StateContext } from '../components/context/ManageStates';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { RiShoppingCart2Fill } from "react-icons/ri";
import DOMPurify from 'dompurify';
import { IoMdShareAlt } from "react-icons/io";
import { FiHeart } from "react-icons/fi";
import { FaHeart } from "react-icons/fa";
import { BsPlus, BsDash } from 'react-icons/bs';
import { Helmet } from "react-helmet";
import ReactPlayer from 'react-player'
import { decode } from 'html-entities';
import { useDispatch, useSelector } from 'react-redux'
const ProductDetail = React.memo(() => {
    // useScrollTop()
    useEffect(() => {
        dispatch({
            type: 'setHeadercategory',
            payload: false,
        });
    }, []);
    const addtosidebar = () => {
        // dispatch({ type: 'setsidebar', payload: !sidebar })
    }
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams();
    const [deliverydate, setDeliverydate] = useState("")
    const [state1, setactivestate1] = useState(1);
    const [selling_price, setselling_price] = useState(0);
    const [addonselling_price, setaddonselling_price] = useState(0);
    const [mrp, setmrp] = useState(0);
    const [addonmrp, setaddonmrp] = useState(0);
    const showSidebar = useSelector(state => state.masterdata.showSidebar)
    const [carousel_images, set_carousel_images] = useState([]);
    const authStatus = useSelector(state => state.masterdata.authStatus);
    const bundlesdata = useSelector(state => state.productdetail.bundlesdata)
    const bundleapicall = useSelector(state => state.productdetail.bundleapicall)
    const Bundelamount = useSelector(state => state.productdetail.Bundelamount)
    const bundle_apislug = useSelector(state => state.productdetail.bundle_apislug)
    const prodDetailBottomSection = useSelector(state => state.productdetail.prodDetailBottomSection);
    const quantity = useSelector(state => state.productdetail.quantity);
    const Deliverydata = useSelector(state => state.masterdata.Deliverydata);
    const addon = useSelector(state => state.productdetail.addon);
    const productDetailData = useSelector(state => state.productdetail.productDetailData);
    const productdata = useSelector(state => state.productdetail.productdata);
    const cartDetail = useSelector(state => state.cartdetail.cartDetail);
    const [price, setPrice] = useState()
    // const price = price + productdata.map(ele=>{ele.selling_price})
    // setPrice()


    const sanitizedHTML = DOMPurify.sanitize(productDetailData && productDetailData[0] && productDetailData[0].product_description && productDetailData[0].product_description);



    const colors = ['red', 'blue', 'green', 'yellow', 'purple'];

    const smallestLeadtime = Deliverydata && Deliverydata.length > 0 && Deliverydata.reduce((prev, curr) => {
        return Number(prev.leadtime) < Number(curr.leadtime) ? prev : curr;
    });
    const today = new Date();

    // Calculate future date based on lead time
    const leadtimeInDays = smallestLeadtime ? Number(smallestLeadtime.leadtime) : 0; // Fallback to 0 if leadtime is unavailable
    const futureDate = new Date(today);
    futureDate.setDate(futureDate.getDate() + leadtimeInDays);

    // Format the future date
    const formattedDeliveryDate = futureDate.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    });
    useEffect(() => {
        // setaddonselling_price(parseInt(selling_price))
        // Initialize total to 0 before summing
        let totalselling_price = 0;
        let totalmrp = 0;

        // Calculate the total selling price
        productdata.forEach((ele) => {
            totalselling_price += parseFloat(ele.selling_price);
        });
        productdata.forEach((ele) => {
            totalmrp += parseFloat(ele.mrp);
        });
        // Add the computed total to the existing selling_price state
        setaddonselling_price((selling_price) + (totalselling_price));
        setaddonmrp((mrp) + (totalmrp));
    }, [productdata, selling_price, mrp]);

    const isMobileorDesktop = useMediaQuery({ query: '(max-width: 991px)' })

    const [addcartbtn, setbtn] = useState(false);
    const [percentage, setpercentage] = useState('');

    const { slug } = useParams();


    const { getProductDetail, SaveRecentlyViewed, getRelatedItems, getbundlelist } = ProductAction();
    const { addToCart, addToWishList, addToCartProduct } = CartAction(searchParams);
    const { customerreviewdata } = CartAction();
    const { getDeliveryChargesdata } = MasterAction();

    const updateQuantity = (qty) => {
        dispatch({ type: 'setquantity', payload: qty })
    }
    useEffect(() => {
        // dispatch({
        //     type: 'setproductDetailData',
        //     payload: true
        // })
        if (bundle_apislug) {
            const input_data = {
                slug: bundle_apislug
            }
        }
        dispatch(getDeliveryChargesdata())
    }, [])
    useEffect(() => {
        set_carousel_images([])
        dispatch({
            type: 'setproductDetailData',
            payload: []
        });
        dispatch({
            type: 'setprodDetailBottomSection',
            payload: []
        });
        dispatch({
            type: 'setquantity',
            payload: 1
        });
        dispatch({
            type: 'setproductslug',
            payload: slug
        });
        var input_data = {
            "slug": slug,
            "session_id": localStorage.getItem("sessionId") == null ? "0" : localStorage.getItem("sessionId")
        }


        dispatch(getRelatedItems(input_data))
        dispatch(customerreviewdata(input_data))
        {
            const url = new URL(window.location.href);
            const type = url.searchParams.get('type');
            if (type == "bundle") {
                dispatch(getbundlelist(input_data))
            }
            else {
                dispatch(getProductDetail(input_data))
            }
        }
        // setaddon([])

        // setquantity(1)

    }, [slug])

    const { data } = ProductJsonData();

    const isDesktoporLaptop = useMediaQuery({ query: `(max-width: 991px)` });

    const [desc_more, setdesc_more] = useState(false);
    const [addtocartstatuus, setaddtocartstatuus] = useState(false);

    const productDetail = data.filter(ele => ele.id == slug)[0];

    const [showLines, setShowLines] = useState(4);

    const handelViewLess = () => {
        setShowLines(4);
    };
    function decodeHtml(html) {
        // const parser = new DOMParser();
        // return parser.parseFromString(html, "text/html").documentElement.textContent;

        return decode(html)
    }

    const rawDescription = productDetailData[0] && productDetailData[0].product_description && productDetailData[0].product_description;
    const decodedDescription = decodeHtml(rawDescription);
    const handleClick1 = (id) => {
        setactivestate1(id)
    }
    useEffect(() => {
        if (cartDetail.result.length > 0) {
            var status = (cartDetail.result.filter(ele => (ele.cart_status == 1 && ele.product_id == productDetailData && productDetailData[0] && productDetailData[0].productid && productDetailData[0].productid)))

            if (status.length > 0) {
                setaddtocartstatuus(true)
            } else {
                setaddtocartstatuus(false)
            }
        }
    }, [cartDetail])

    useEffect(() => {
        const randomId = Math.floor(Math.random() * 100000000);
        if (productDetailData.length > 0) {
            window.scrollTo(0, 0)
            if (localStorage.getItem("sessionId") == null) {
                localStorage.setItem('sessionId', randomId)
            }
            var input_data1 = {
                "product_id": productDetailData[0].productid,
                "session_id": localStorage.getItem("sessionId")
            }
            dispatch(SaveRecentlyViewed(input_data1))
            var input_data = {
                "productid": productDetailData[0].productid,
                ...(productDetailData[0].subcategory_id != 0 && {
                    "subcategory_id": productDetailData[0].subcategory_id
                }),
                ...(productDetailData[0].subcategory_id == 0 && {
                    "category_id": productDetailData[0].category_id
                }),
                "session_id": localStorage.getItem("sessionId") == null ? "0" : localStorage.getItem("sessionId")
            }

            setTimeout(function () {
            }, 1000)

            if (searchParams.has("variant")) {
                var finaloutput = searchParams.get("variant");
                var op = productDetailData[0].variantList.filter(ele => ele.variantid == finaloutput.toString())
                if (op.length > 0) {
                    var len = (cartDetail.result.filter(ele => (ele.cart_status == 2 && ele.variantname == op[0].variantname && ele.product_id == productDetailData[0].productid)))
                    if (len.length > 0) {
                        setbtn(true)
                    } else {
                        setbtn(false)
                    }
                    setselling_price(op[0].selling_price)
                    setmrp(op[0].mrp)
                    var price = op[0].mrp;
                    var diff = price - op[0].selling_price;
                    var calpercentage = diff / price * 100;
                    setpercentage(Math.round(calpercentage))
                    if (op[0].hasOwnProperty("product_images")) {
                        set_carousel_images(op[0].product_images)
                    } else {
                        set_carousel_images(productDetailData[0].product_images)
                    }
                } else {

                }
            } else {

                var price = productDetailData[0].mrp;
                var diff = price - productDetailData[0].selling_price;
                var calpercentage = diff / price * 100;
                setpercentage(Math.round(calpercentage))
                setselling_price(productDetailData[0].selling_price)
                setmrp(productDetailData[0].mrp)
                set_carousel_images(productDetailData[0].product_images)
            }
        }
    }, [productDetailData])

    // useEffect(() => {
    //     if (!productDetailData[0]) return;

    //     const { slug, product_type } = productDetailData[0];

    //     if (product_type === "bundle") {
    //       dispatch(getbundlelist({ slug }));
    //     }
    //   }, [productDetailData, dispatch]);


    useEffect(() => {
        dispatch({ type: 'setquantity', payload: 1 })

        var queryObj = {};
        for (const entry of searchParams.entries()) {
            queryObj = Object.fromEntries([...searchParams]);
        }
        var finalInput = Object.entries(queryObj).map(([key, value]) => ({
            title: key,
            value: value.split(",").map((ele) => ele.split("_")[0]),
        }))
        if (finalInput.length > 0) {
            var finaloutput = finalInput.flatMap(o => o.value);
            if (productDetailData.length > 0) {
                var op = productDetailData[0].variantList.filter(ele => ele.variantid == finaloutput.toString())
                if (op.length > 0) {
                    var len = (cartDetail.result.filter(ele => (ele.cart_status == 2 && ele.variantname == op[0].variantname && ele.product_id == productDetailData[0].productid)))
                    if (len.length > 0) {
                        setbtn(true)
                    } else {
                        setbtn(false)
                    }
                    setselling_price(op[0].selling_price)
                    setmrp(op[0].mrp)
                    var price = op[0].mrp;
                    var diff = price - op[0].selling_price;
                    var calpercentage = diff / price * 100;
                    setpercentage(Math.round(calpercentage))
                    if (op[0].hasOwnProperty("product_images")) {
                        set_carousel_images(op[0].product_images)
                    } else {
                        set_carousel_images(productDetailData[0].product_images)
                    }
                }
            }
        }
    }, [searchParams])

    //............................................productdetail sidebar
    // const [showSidebar, setShowSidebar] = useState(false);
    const handleAddToCart = (quantity, image, price,slug) => {
        dispatch(addToCart(productDetailData[0].productid, 'product'));
        dispatch({
            type: 'setShowSidebar',
            payload: true
        });
        dispatch({
            type: 'setcartsidebardata',
            payload: {
                quantity: quantity,
                image: image,
                price: price,
                slug:slug
            }
        });
        document.body.style.overflow = "hidden";
    };



    useEffect(() => {
        var queryObj = {};
        for (const entry of searchParams.entries()) {
            queryObj = Object.fromEntries([...searchParams]);
        }

        var finalInput = Object.entries(queryObj).map(([key, value]) => ({
            title: key,
            value: value.split(",").map((ele) => ele.split("_")[0]),
        }))


        if (finalInput.length > 0) {
            var finaloutput = finalInput.flatMap(o => o.value);
            if (productDetailData.length > 0) {
                var op = productDetailData[0].variantList.filter(ele => ele.variantid == finaloutput.toString())
                if (op.length > 0) {
                    var len = (cartDetail.result.filter(ele => (ele.cart_status == 2 && ele.variantname == op[0].variantname && ele.product_id == productDetailData[0].productid)))
                    if (len.length > 0) {
                        setbtn(true)
                    } else {
                        setbtn(false)
                    }
                }
            }
        } else {
            if (productDetailData.length > 0 && cartDetail.result.length > 0) {
                var len = (cartDetail.result.filter(ele => (ele.cart_status == 2 && ele.product_id == productDetailData[0].productid)))

                if (len.length > 0) {
                    setbtn(true)
                } else {
                    setbtn(false)
                }
            }
        }
    }, [cartDetail, productDetailData])

    useEffect(() => {
        if (productDetailData.length > 0) {
            setselling_price(parseFloat(productDetailData[0].selling_price) + addon.reduce(function (acc, obj) { return acc + parseInt(obj.selling_price) * obj.quantity; }, 0));
        }
    }, [addon])

    const MerchantSnippet = () => {
        return (
            <script type="application/ld+json">
                {JSON.stringify({
                    "@context": "https://schema.org",
                    "@type": "Organization",
                    "Name": localStorage.getItem("agency_name"),
                    "URL": window.location.origin,
                    "contactPoint": [{
                        "@type": "ContactPoint",
                        "telephone": localStorage.getItem("vendor_contact"),
                        "contactType": "Customer Service"
                    }],
                    "logo": localStorage.getItem("company_logo"),
                    "sameAs": [
                        "https://www.instagram.com/jhaluck.india"
                    ]
                })}
            </script>
        );
    };

    const ProductSnippet = ({ product }) => {
        return (
            <script type="application/ld+json">
                {JSON.stringify(
                    {
                        "@context": "https://schema.org",
                        "@type": "Product",
                        "name": product.product_title,
                        "image": product.product_images[0],
                        "sku": product.sku,
                        "mpn": product.sku,
                        "description": product.description,
                        "offers": {
                            "@type": "Offer",
                            "priceCurrency": "INR",
                            "availability": "InStock",
                            "price": product.selling_price,
                            "url": `${window.location.origin}/product/${product.slug}`
                        }
                    }
                )}
            </script>
        );
    };
    const [addproductid, setAddproductid] = useState({})

    const AddonAddtocart = (productid) => {

        if (!authStatus) {
            const randomId = Math.floor(Math.random() * 100000000);
            if (localStorage.getItem("sessionId") === null) {
                localStorage.setItem('sessionId', randomId);
            }
        }
        // setAddproductid(productid)
        // setAddproductid( [...addproductid, productdata.map(ele => ele.productid)])
        const input_data = {
            product_id: productid,
            // addonproduct_id: productdata.map(ele => ele.productid),
            quantity: quantity,
            type: "product",
            ...(authStatus === false && { session_id: localStorage.getItem("sessionId") })
        }
        dispatch(addToCartProduct(input_data))
        {
            productdata.map(ele => {
                const input_data = {
                    product_id: ele.productid,
                    quantity: quantity,
                    type: "product",
                    ...(authStatus === false && { "session_id": localStorage.getItem("sessionId") })
                }
                dispatch(addToCartProduct(input_data))
            })
        }
    }

    const handleShare = async (title, url, imageUrl) => {
        try {
            const response = await fetch(imageUrl);
            // here image is url/location of image
            const blob = await response.blob();
            const file = new File([blob], 'share.webp', { type: 'image/webp' });

            if (productDetailData[0].variantList.length > 0) {
                var detail_url = `${window.location.origin}/product/${url}?variant=${productDetailData[0].variantList[0].variantid}`;
            } else {
                var detail_url = `${window.location.origin}/product/${url}`;
            }

            const shareContent = {
                title: 'take a look at this product',
                text: title,
                url: detail_url,
                files: [file]
            };
            await navigator.share(shareContent);
        } catch (error) {
        }
    };

    return (
        <>

            <Container fluid style={{ marginTop: "10px" }}>
                {
                    productDetailData.length > 0 ?

                        <div>
                            <Helmet>
                                <title>{localStorage.getItem("title") + " - " + productDetailData[0].product_title}</title>
                                <meta property='description' content={productDetailData[0].seo_description} />
                                <meta property='keywords' content={productDetailData[0].seo_keyword} />
                                <link rel="canonical" href={window.location.origin + window.location.pathname} />
                                <meta property='title' content={productDetailData[0].seo_title} />


                                {/* <meta property="og:title" content="Your Title Here" />
                                <meta property='og:description' content="Your description here" />
                                <meta property='og:image' content="http://example.com/image.jpg" />
                                <meta property="og:url" content="http://example.com/page" /> */}


                            </Helmet>
                            {<ProductSnippet product={productDetailData[0]} />}
                            {<MerchantSnippet />}
                            <BreadComp title0={productDetailData[0].parent_category_name} titlecat0={productDetailData[0].category_name} urlcat0={`/category/${productDetailData[0].category_slug}`} url0={`/category/${productDetailData[0].parent_category_slug}`} title1={productDetailData[0].parent_subcategory_name} titlesubcat1={productDetailData[0].subcategory_name} urlsubcat1={`/subcategory/${productDetailData[0].subcategory_slug}`} url1={`/subcategory/${productDetailData[0].parent_subcategory_slug}`} title2={productDetailData[0].parent_subsubcategory_name} titlesubsubcat2={productDetailData[0].subsubcategory_name} url2={`/subcategory/${productDetailData[0].parent_subsubcategory_slug}`} urlsubsubcat2={`/subcategory/${productDetailData[0].subsubcategory_slug}`} title3={productDetailData[0].subsubcategory_name} title4={productDetailData[0].product_title} />
                            <Row className='product-detail-main'>

                                <Col lg={6} className={`scroll-content-left ${!isDesktoporLaptop ? "padding-right-unset" : ""}`}>
                                    {
                                        !isDesktoporLaptop ?
                                            <div className=' productdetailimages'>
                                                <Row>
                                                    <ProductDetailGallery product_images={carousel_images} utube_url={productDetailData[0].video_link} />
                                                </Row>
                                            </div>
                                            :
                                            <>
                                                <Row>
                                                    <ProductDetailSwiperMobile productDetail={productDetailData[0]} utube_url={productDetailData[0].video_link} />
                                                    {/* <div className='buttons-product-detaildiv'>
                                                    <div className='product-detail-buttongroup'>
                                                        <div className={`product-detail-button `}
                                                            onClick={() =>
                                                                dispatch(addToCart(productDetailData[0].productid, 'product'))
                                                            }
                                                        >
                                                            Add To Cart
                                                        </div>
                                                        <div className='product-detail-button btn-2'>
                                                            Buy Now
                                                        </div>
                                                    </div>
                                                </div> */}
                                                </Row>
                                            </>
                                    }
                                </Col>

                                <Col lg={6} className={` scroll-content-right ${!isDesktoporLaptop ? "padding-left-unset" : ""}`}>
                                    <div className='product-rightside'>
                                        <div className='product_desktop_margin'>
                                            {
                                                productDetailData[0].brand_name != null &&
                                                <div className='productdetail_brand'>{productDetailData[0].brand_name}</div>
                                            }
                                            <div className='product-top '>
                                                {
                                                    // bundlesdata.product_title ?
                                                    //     <div className='product-titlebold-heading'>
                                                    //         {bundlesdata.product_title}
                                                    //     </div> :
                                                    <div className='product-titlebold-heading'>
                                                        {productDetailData[0].product_title}
                                                    </div>
                                                }

                                                <div className='heart-productdeatail'>
                                                    <div className='product-price'>
                                                        <div className="product-price-revamp">
                                                            {
                                                                productDetailData[0] &&
                                                                    productDetailData[0].productList &&
                                                                    productDetailData[0].productList.length > 0 ?
                                                                    <span className="productactual-price Paragraph-16-M--Semibold">< span className="currencysymbol productdetailcurrencysymbol">{localStorage.getItem("currency")} </span>{ Number(Bundelamount).toFixed(2)}</span> :
                                                                    <span className="productactual-price Paragraph-16-M--Semibold">< span className="currencysymbol productdetailcurrencysymbol">{localStorage.getItem("currency")} </span>{ Number(addonselling_price).toFixed(2)}</span>
                                                            }
                                                        </div>

                                                    </div>
                                                    {
                                                        //    isMobileorDesktop && 
                                                        (
                                                            authStatus ?
                                                                (addcartbtn ?

                                                                    <div className='product-detail-button  productdetai_wishlist_share btn-2'>
                                                                        {
                                                                            !searchParams.has("type") &&
                                                                            <NavLink className='text-decoration-none primaryColorcolor' style={{ lineHeight: "15px", cursor: "pointer" }} to="/wishlist">
                                                                                <div className='share-productdetails'>
                                                                                    <FaHeart className="product-detail-btn-icon " />
                                                                                </div>
                                                                            </NavLink>
                                                                        }
                                                                        <div className='share-productdetails share_icon'>
                                                                            <IoMdShareAlt style={{ marginTop: "-4px", cursor: "pointer", }} size={20} onClick={() => handleShare(productDetailData[0].product_title, productDetailData[0].slug, productDetailData[0].product_images[0])} />
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    <>
                                                                        <div style={{ lineHeight: "15px", cursor: "pointer" }} className='product-detail-button productdetai_wishlist_share  btn-2'

                                                                        >
                                                                            {
                                                                                !searchParams.has("type") &&
                                                                                <div className='share-productdetails'>


                                                                                    <FiHeart className="product-detail-btn-icon" onClick={() => dispatch(addToWishList(productDetailData[0].productid, 'product'))} />
                                                                                </div>
                                                                            }
                                                                            <div className='share-productdetails share_icon'>
                                                                                <IoMdShareAlt style={{ marginTop: "-4px", cursor: "pointer", }} size={20} onClick={() => handleShare(productDetailData[0].product_title, productDetailData[0].slug, productDetailData[0].product_images[0])} />
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                )
                                                                :
                                                                <div className='product-detail-button productdetai_wishlist_share'>
                                                                    {
                                                                        !searchParams.has("type") &&
                                                                        <NavLink className="text-decoration-none" style={{ lineHeight: "15px", cursor: "pointer" }} to={`/login?redirect_url=product`} >
                                                                            <div className='share-productdetails'>


                                                                                <FiHeart className="product-detail-btn-icon" />
                                                                            </div>
                                                                        </NavLink>
                                                                    }
                                                                    <div className='share-productdetails share_icon '>
                                                                        <IoMdShareAlt style={{ marginTop: "-4px", cursor: "pointer" }} size={20} onClick={() => handleShare(productDetailData[0].product_title, productDetailData[0].slug, productDetailData[0].product_images[0])} />
                                                                    </div>
                                                                </div>
                                                        )}
                                                </div>
                                                <div className='d-flex'>
                                                    {
                                                        addonselling_price != addonmrp &&
                                                        <span className="offer-label-revamp  productdetail_discount Paragraph-12-XS--Semibold">{Math.ceil(100 - (addonselling_price / addonmrp) * 100)}% </span>
                                                    }
                                                    {
                                                        addonselling_price != addonmrp &&
                                                        <span className="offer offer-revamp  cross-price Paragraph-16-M--Regular">< span className="currencysymbol">{localStorage.getItem("currency")} </span>{ Number(addonmrp).toFixed(2)}</span>
                                                    }
                                                </div>


                                            </div>
                                            {
                                                // productDetailData[0].short_description !== "" &&
                                                // <h1 class="pro-desc-details">
                                                //     {productDetailData[0].short_description}
                                                //     {/* <h1 dangerouslySetInnerHTML={{ __html: productDetailData[0].product_title }} /> */}
                                                // </h1>
                                            }
                                            {
                                                Deliverydata && Deliverydata.length > 0 &&
                                                <div>
                                                    <div className='space-20'></div>
                                                    <div className='d-flex'>

                                                        <div className='deliverystatus_productdetail'><BsTruck size={14} /> <span className='pl-1'>{smallestLeadtime.deliveryzone}</span></div>
                                                        <div className='deliverystatus_productdetaildate'>
                                                            Get it by {formattedDeliveryDate}
                                                        </div>
                                                    </div>
                                                </div>
                                            }

                                            {
                                                productDetailData[0].variantList.length > 0 &&
                                                <div className='space-20'></div>
                                            }

                                            {
                                                productDetailData[0].attributes.filter(ele => ele.variant_flag == '1').map((ele, countindex) => (
                                                    <div >

                                                        <ProductVariants item={ele} countindex={countindex} />
                                                    </div>
                                                ))
                                            }
                                            {
                                                productDetailData[0].variantList.length > 0 && (!isDesktoporLaptop ?

                                                    <div className='space-20'></div>
                                                    :
                                                    <div className='space-15'></div>)
                                            }
                                            {
                                                // !isDesktoporLaptop &&
                                                // <div className='space-15'></div>
                                            }
                                            {
                                                productDetailData[0].addOnList.length > 0 &&
                                                <Addons data={productDetailData[0].addOnList} />

                                            }
                                            {
                                                productDetailData[0].productList && productDetailData[0].productList.length > 0 &&
                                                <>
                                                    <div className='space-20'></div>
                                                    <Bundles bundledata={productDetailData[0].productList} price={productDetailData[0].selling_price} stock_status={productDetailData[0].stock_status} />
                                                </>

                                            }
                                            {!searchParams.has("type") &&
                                                productDetailData[0].addOnList.length <= 0 &&
                                                <Row className='mt-4 '>
                                                    <Col lg={2} xxl={2} sm={4} xs={4} className='cart-quentity-div'>

                                                        <div className={`card-detail-secondrow1 ${productDetailData[0].stock_status != 1 ? "swiper-button-disabled" : ""}`}>
                                                            <div className='quantityContainer1'>
                                                                <div
                                                                    className={`plusButton1 `}
                                                                    onClick={() => updateQuantity(quantity == productDetailData[0].moq ? parseInt(productDetailData[0].moq) : quantity - 1)}
                                                                >
                                                                    <BsDash size="22" className='plusButton1-btn-icon' />
                                                                </div>

                                                                <div className="quantity1">
                                                                    {quantity}
                                                                </div>

                                                                <div
                                                                    className="plusButton1"
                                                                    onClick={() => updateQuantity(quantity + 1)}
                                                                >
                                                                    <BsPlus className='plusButton1-btn-icon' size="22" />
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </Col >
                                                </Row>
                                            }

                                            {
                                                !searchParams.has("type") &&
                                                <Row className='mt-4 '>

                                                    {

                                                        <Col lg={6} xxl={6} md={6} sm={6} xs={6} className='buttons-product-detaildiv' >
                                                            <div className='product-detail-buttongroup  product-detal-cart_btn '>
                                                                {
                                                                    authStatus ? (
                                                                        !addcartbtn ?

                                                                            !searchParams.has("type") &&
                                                                            <div className={`productdetails-add-to-wishlist `} onClick={() => dispatch(addToWishList(productDetailData[0].productid, 'product'))} >
                                                                                Add To Wishlist
                                                                            </div>
                                                                            :
                                                                            !searchParams.has("type") &&
                                                                            <div className={`productdetails-add-to-wishlist `}  >
                                                                                Wishlisted
                                                                            </div>
                                                                    ) :
                                                                        <NavLink className="text-decoration-none" to={`/login?redirect_url=product`} >
                                                                            <div className={`productdetails-add-to-wishlist `}  >
                                                                                Add To Wishlist
                                                                            </div>
                                                                        </NavLink>
                                                                }

                                                            </div>
                                                        </Col >
                                                    }
                                                    {
                                                        <Col lg={6} xxl={6} md={6} sm={6} xs={6} className='buttons-product-detaildiv'>
                                                            <div className='product-detail-buttongroup  product-detal-cart_btn '>

                                                                {
                                                                    productDetailData[0].stock_status == 1 ?

                                                                        productDetailData[0].addOnList.length > 0 ?
                                                                            <div style={{ lineHeight: "15px" }} className={`product-detail-button cart-btn primaryColor ${productDetailData[0].stock < 1 ? "swiper-button-disabled" : ""}`}
                                                                                onClick={() => {
                                                                                    AddonAddtocart(productDetailData[0].productid)

                                                                                }
                                                                                }
                                                                            >
                                                                                {/* <RiShoppingCart2Fill className="product-detail-btn-icon" /> */}
                                                                                Add to Cart
                                                                            </div>
                                                                            :
                                                                            <div>
                                                                                {
                                                                                    addtocartstatuus ?
                                                                                        <div
                                                                                            style={{ lineHeight: "15px" }}
                                                                                            className={`product-detail-button cart-btn primaryColor dtailpage_addtocartbtn ${productDetailData[0].stock < 1 ? "swiper-button-disabled" : ""}`}

                                                                                        >
                                                                                            Added to Cart
                                                                                        </div> :
                                                                                        <div
                                                                                            style={{ lineHeight: "15px" }}
                                                                                            className={`product-detail-button cart-btn primaryColor dtailpage_addtocartbtn ${productDetailData[0].stock < 1 ? "swiper-button-disabled" : ""}`}
                                                                                            onClick={() => {
                                                                                                handleAddToCart(quantity,
                                                                                                     productDetailData[0].product_images[0], productDetailData[0] &&
                                                                                                    productDetailData[0].productList &&
                                                                                                    productDetailData[0].productList.length > 0 ?
                                                                                                    Bundelamount :
                                                                                                    addonselling_price,
                                                                                                    productDetailData[0].slug
                                                                                                )
                                                                                            }}
                                                                                        >
                                                                                            Add to Cart
                                                                                        </div>

                                                                                }

                                                                            </div>
                                                                        :

                                                                        <div style={{ lineHeight: "15px", cursor: 'not-allowed' }} className={`product-detail-button primaryColor dtailpage_addtocartbtn cart-btn ${productDetailData[0].stock < 1 ? "swiper-button-disabled" : ""}`}

                                                                        >

                                                                            {/* <RiShoppingCart2Fill className="product-detail-btn-icon" /> */}
                                                                            Out Of stock

                                                                        </div>
                                                                }
                                                                {/* <div className={`custom-stock stockstatus ${productDetailData[0].stock_status != 1 ? "color-primary_color" : ""}`}>{productDetailData[0].stock_status == 1 ? 'In Stock' : ''}</div> */}



                                                            </div>


                                                        </Col>
                                                    }
                                                </Row>
                                            }
                                        </div>
                                        {/* <DetailBankOffers /> */}
                                        <div className='product_desktop_margin'>
                                            {
                                                Deliverydata && Deliverydata.length > 0 &&
                                                <div>
                                                    <h5 className='detail_Specifications'>
                                                        Delivery
                                                    </h5>
                                                    <Row>
                                                        {Deliverydata && Deliverydata.length > 0 &&
                                                            Deliverydata.map((ele) => {
                                                                return (
                                                                    <Col lg={6} key={ele.id}>
                                                                        <div
                                                                            className={`detaildelevery `}

                                                                        >
                                                                            <div className="detailpage_deliveryleft">
                                                                                <div className="delivery_title">{ele.deliveryzone}</div>
                                                                                {
                                                                                    ele.leadtime && ele.leadtime.length > 0 &&
                                                                                    <div className="delivery_days">{ele.display_text}</div>
                                                                                }

                                                                            </div>
                                                                            <div className="deliverystatus">
                                                                                {ele.delivery_charges > 0 ? `${localStorage.getItem("currency")} ${ele.delivery_charges} ` : "FREE"}
                                                                            </div>
                                                                        </div>
                                                                    </Col>
                                                                );
                                                            })}
                                                    </Row>
                                                </div>
                                            }


                                            <div className='product_detail_attributes'>

                                                {
                                                    productDetailData[0].product_description !== "" &&
                                                    <>
                                                        <h5 class="detail-description">
                                                            Overview
                                                        </h5>
                                                        <div className='product_description'>
                                                            <div
                                                                dangerouslySetInnerHTML={{
                                                                    __html: decodedDescription,
                                                                }}
                                                            />

                                                            {/* <div>{productDetailData[0].product_description}</div> */}
                                                            {/* <div dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />; */}
                                                            {/* <div dangerouslySetInnerHTML={{ __html: productDetailData[0].product_description }} /> */}
                                                            {/* <Markup content={productDetailData[0].product_description} /> */}
                                                        </div>
                                                    </>
                                                }
                                                {
                                                    productDetailData[0].attributes.length > 0 &&
                                                    <h5 className='detail_Specifications'>
                                                        Specifications
                                                    </h5>
                                                }

                                                {
                                                    productDetailData[0].attributes.map((ele, index) => {
                                                        return (
                                                            <div className="product_detail_attributes_parent" key={index}
                                                                style={{
                                                                    backgroundColor: index % 2 == 0 ? '#C23A971A' : '', // Apply background for odd index
                                                                }}
                                                            >
                                                                {/* <span className="col-4">Attributes</span> */}
                                                                <div className="attributes-item d-flex">
                                                                    <span className="col-4 attributes-item-left">{ele.attribute_name}</span>
                                                                    <span className="col-7 attributes-item-right">
                                                                        {
                                                                            ele.options.map((ele1, index1) => {
                                                                                return ele1.attr_value_name + ((ele.options.length != (index1 + 1)) ? " , " : "")
                                                                            })
                                                                        }
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }

                                                {
                                                    productDetailData[0].product_dimension && productDetailData[0].product_dimension != 0 &&
                                                    <div className="attributes-item d-flex">
                                                        <span className="col-4">Dimensions</span>
                                                        <span className="col-7 attributes-item-right ">{productDetailData[0].product_dimension} </span>
                                                    </div>

                                                }
                                                {
                                                    productDetailData[0].product_weight && productDetailData[0].product_weight != 0 &&
                                                    <div className="attributes-item d-flex">
                                                        <span className="col-4">Weight</span>
                                                        <span className='attributes-item'>{productDetailData[0].product_weight} </span>
                                                    </div>
                                                }



                                                {
                                                    productDetailData[0].video_link && productDetailData[0].video_link != null &&
                                                    <div>
                                                        <ReactPlayer url={productDetailData[0].video_link} width={"100%"} height={400} controls={true} />
                                                    </div>
                                                }

                                            </div>
                                        </div>
                                        <RatingNew />
                                        {
                                            isDesktoporLaptop ?

                                                <div className='space-15'></div>
                                                :
                                                <div className='space-30'></div>

                                        }
                                        <div className="mt-1 product_Short_description">

                                            {/* <Markup content={productDetailData[0].short_description} /> */}
                                        </div>




                                        {/* <div className='mt-2'>
                                    <BundleDeals />
                                </div> */}
                                        {/* <CustomAccordion productDetailData={productDetailData[0]} /> */}
                                        <div className='space-10'></div>


                                        {/* {!desc_more && <div className="readmore" onClick={() => setdesc_more(!desc_more)}>See more</div>} */}

                                    </div>
                                    {
                                        (productDetailData[0].crossSellList).length > 0 &&
                                        <div>
                                            <div className='Quality-products'>
                                                <div className='Quality-products-details-left1'>

                                                </div>
                                                <h5 className='Quality-products-details-left2'>
                                                    Frequently bought together
                                                </h5>
                                            </div>
                                            {
                                                !isMobileorDesktop ?
                                                    <div className='ProductBought-main-head'><CrosSellList data={productDetailData[0].crossSellList} /></div> :
                                                    <div className='ProductBought-main-head-mobile'><CrosSellListMobile data={productDetailData[0].crossSellList} /></div>
                                            }
                                        </div>
                                    }


                                </Col>
                            </Row>
                            <Col lg={12} className="mt-4">
                                {
                                    prodDetailBottomSection.length > 0 &&
                                    prodDetailBottomSection.map((comp, index) => {
                                        if (comp.component == 'carousel1') {
                                            return (
                                                comp.sectionResult.length > 0 &&
                                                <div className="home-carousel-bg-wihte" key={index}>
                                                    <SingleCarousel single={true} carouseldata={comp.sectionResult} heading={comp.section_title} position={6} description={comp.description} />
                                                </div>
                                            )
                                        }
                                        if (comp.component == 'carousel2') {

                                            return (

                                                comp.sectionResult.length > 0 &&

                                                <div className="home-carousel-bg-wihte" key={index}>
                                                    <SingleCarousel single={true} carouseldata={comp.sectionResult} heading={comp.section_title} position={7} description={comp.description} />
                                                    {/* <CarouselComp2 position={1} loopindex={index} carouselCompData={comp} link={"product-detail"} className={"aaass"} /> */}
                                                </div>

                                            )
                                        }

                                    })
                                }
                                {/* <ReviewRatingSection position={1} loopindex={21} /> */}
                            </Col>
                            {/* {
                                productDetailData[0].addOnList.length > 0 &&
                                <>
                                    <div className='divider mt-3'></div>
                                    <h5 className='slot_custom_heading color-black'>Make it extra special</h5>
                                </>
                            }

                            <div className='home-carousel-bg-wihte mt-2'>
                                {
                                    productDetailData[0].addOnList.map((ele, index) => (
                                        <div className='mb-2'>
                                            <HomeCarouselCardComponentDup heading={ele.addon_name} type={"jjj"} link={"nh"} carouselCompData={ele.addon_products} position={index + "1"} />
                                        </div>
                                    ))
                                }
                            </div> */}




                        </div>
                        :
                        // <ProductDetailNodata />
                        <div className='custom-height'></div>
                }

            </Container>
        </>
    );
});

export default ProductDetail;
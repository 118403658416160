const initialState = {
  openModal: false,
  cartDetail: {
    result: []
  },
  cartDetaildup: undefined,
  coupanmsg: [],
  error: null,
  bottomsheet: false,
  discount: 0,
  appleuser: null,
  slectedphonecode: "+91",
  updatephoneno: "",
  updateemail: 0,
  profileemailnew: false,
  profileupdatemail: "",
  storeemail:"",

};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'setappleuser':
      return {
        ...state,
        appleuser: action.payload
      };
    case 'setstoreemail':
      return {
        ...state,
        storeemail: action.payload
      };
    case 'setprofileupdatemail':
      return {
        ...state,
        profileupdatemail: action.payload
      };
    case 'setprofileemailnew':
      return {
        ...state,
        profileemailnew: action.payload
      };
    case 'setupdateemail':
      return {
        ...state,
        updateemail: action.payload
      };
    case 'setupdatephoneno':
      return {
        ...state,
        updatephoneno: action.payload
      };
    case 'setslectedphonecode':
      return {
        ...state,
        slectedphonecode: action.payload
      };
    case 'cart_list':
      return {
        ...state,
        cartDetail: action.payload
      };
    case 'setcartDetaildup':
      return {
        ...state,
        cartDetaildup: action.payload
      };
    case 'setCoupanmsg':
      return {
        ...state,
        coupanmsg: action.payload
      };
    case 'setbottomsheet':
      return {
        ...state,
        bottomsheet: action.payload
      };
    case 'setopenModal':
      return {
        ...state,
        openModal: action.payload
      };
    case 'setdiscount':
      return {
        ...state,
        discount: action.payload
      };
    default:
      return state;
  }
};
